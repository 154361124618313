import { createSlice } from "@reduxjs/toolkit"
import { createAppAsyncThunk } from "app/hooks"
import paymentAPI from "./paymentAPI"
import { FIRST_REMAINING_LOG_MEAL_TIME_TO_SHOW_PAYWALL, resetStoreAction } from "config"
import { IPaymentState } from "./types"

export const getPlans = createAppAsyncThunk(
  "payment/getPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getPlans()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getCheckoutStatus = createAppAsyncThunk(
  "payment/getCheckoutStatus",
  async (sessionId: string, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getCheckoutStatus(sessionId)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getUserPlan = createAppAsyncThunk(
  "payment/getUserPlan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getUserPlan()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getPaymentLink = createAppAsyncThunk(
  "payment/getPaymentLink",
  async (productId: string, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getPaymentLink(productId)
      return response.url
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: IPaymentState = {
  remainingLogMealTimeToShowPaywall: FIRST_REMAINING_LOG_MEAL_TIME_TO_SHOW_PAYWALL,

  getPaymentLinkLoading: false,
  paymentLink: undefined,
  getPaymentLinkFailed: undefined,

  getUserPlanFailed: undefined,
  getUserPlanLoading: false,
  userPlan: undefined,

  plans: [],
  getPlansLoading: false,
  getPlansFailed: undefined,

  getCheckoutStatusLoading: false,
  getCheckoutStatusFailed: undefined,
  checkoutStatus: {
    paymentStatus: "",
    status: "",
  },
}

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setRemainingLogMealTimeToShowPaywall(state, action) {
      state.remainingLogMealTimeToShowPaywall = action.payload
      console.log("state.remainingLogMealTimeToShowPaywall", state.remainingLogMealTimeToShowPaywall)
    },

    resetImageState() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getUserPlan.pending, (state) => {
        state.getUserPlanLoading = true
        state.getUserPlanFailed = undefined
        
      })
      .addCase(getUserPlan.fulfilled, (state, action) => {
        state.userPlan = action.payload
        state.getUserPlanLoading = false
      })
      .addCase(getUserPlan.rejected, (state, action) => {
        const { response }: any = action.payload
        const isNotHavePlanYet = response.status === 404
        state.getUserPlanLoading = false

        if (isNotHavePlanYet) {
          state.userPlan = {
            plan:{
              id: "",
              name: "Free",
              subscriptionDuration: "Not yet",
              price: 0,
              entitlements: [
                {
                  name: "Basic",
                  expiredAt: "Basic",
                },
              ],
            },
           
            expiredAt: null,
           
            renew: false,
          }
        } else {
          state.getUserPlanFailed = action.payload
        }
      })

      .addCase(getPaymentLink.pending, (state) => {
        state.getPaymentLinkLoading = true
        state.paymentLink = undefined
        state.getPaymentLinkFailed = undefined
      })
      .addCase(getPaymentLink.fulfilled, (state, action) => {
        state.paymentLink = action.payload
        state.getPaymentLinkLoading = false
      })
      .addCase(getPaymentLink.rejected, (state, action) => {
        state.getPaymentLinkLoading = false
        state.getPaymentLinkFailed = action.payload
      })

      .addCase(getPlans.pending, (state) => {
        state.getPlansLoading = true
        state.getPlansFailed = undefined
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.plans = action.payload
        state.getPlansLoading = false
      })

      .addCase(getPlans.rejected, (state, action) => {
        state.getPlansLoading = false
        state.getPlansFailed = action.payload
      })

      .addCase(getCheckoutStatus.pending, (state) => {
        state.getCheckoutStatusLoading = true
        state.getCheckoutStatusFailed = undefined
      })

      .addCase(getCheckoutStatus.fulfilled, (state, action) => {
        state.checkoutStatus = action.payload
        state.getCheckoutStatusLoading = false
      })

      .addCase(getCheckoutStatus.rejected, (state, action) => {
        state.getCheckoutStatusLoading = false
        state.getCheckoutStatusFailed = action.payload
      })

      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const { resetImageState,setRemainingLogMealTimeToShowPaywall } = paymentSlice.actions

export default paymentSlice.reducer
