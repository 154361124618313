import styled from "styled-components"

export const EditProfileMain = styled.div`
  padding: var(--page-padding);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 16px;
  height: 100%;
`
