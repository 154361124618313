import { useEffect, useState } from "react"
import { IFood } from "../foodTypes"
import { useAppSelector } from "app/hooks"
import { v4 as uuidv4 } from "uuid"
import { isNotAlreadyFood } from "utils"
import { IScannedFood } from "features/meal/types"

export const useScannedFoods = () => {
  const scanMealResults = useAppSelector((state) => state.meal.scanMealResults)
  const [foods, setFoods] = useState<IScannedFood[]>(scanMealResults)

  useEffect(() => {
    setFoods(scanMealResults)
  }, [scanMealResults])

  const toggleSelectFood = (updatedFood: IScannedFood) => {
    const updatedFoods = foods.map((food) =>
      food.id === updatedFood.id
        ? { ...food, isSelected: !food.isSelected }
        : food,
    )
    setFoods(updatedFoods)
  }

  const onUpdateFood = (updatedFood: IScannedFood) => {
    const updatedFoods = foods.map((food) =>
      food.id === updatedFood.id ? updatedFood : food,
    )
    setFoods(updatedFoods)
  }

  const onAddFood = (food: IFood) => {
    setFoods([
      ...foods,
      {
        ...food,
        isSelected: true,
        id: uuidv4(),
        isNotAlreadyFood: isNotAlreadyFood(food),
      },
    ])
  }

  return {
    foods,
    toggleSelectFood,
    onUpdateFood,
    onAddFood,
  }
}
