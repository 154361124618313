import Page from "components/Common/Page"
import React from "react"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { EditProfileMain } from "./styled"
import { useProfile, useProfileForm, useUpdateProfile } from "../hooks/profile"
import FullPageLoading from "components/Layouts/FullPageLoading"
import TextFieldFormItem from "components/TextFieldFormItem"
import TextFieldDatePickerFormItem from "components/TextFieldDatePickerFormItem"
import Button from "components/Buttons/Button"
import { Divider } from "antd-mobile"
import { useAppSelector } from "app/hooks"
import { FlexBox } from "components/Common/FlexBox"

const EditProfile = () => {
  const { profile, getProfileLoading } = useProfile()
  const { name, setName, birthedOn, setBirthedOn } = useProfileForm(profile)
  const navigate = useNavigate()

  const backToProfile = () => {
    navigate(PATHS.app.profile.root)
  }

  const goToChangeEmail = () => {
    navigate(PATHS.app.profile.edit.changeEmail)
  }

  const goToDeleteAccount = () => {
    navigate(PATHS.app.profile.deleteAccount)
  }

  const goToChangePassword = () => {
    navigate(PATHS.app.profile.edit.getConfirmCodeToChangePassword)
  }

  const email = useAppSelector((state) => state.auth.email)

  const { handleUpdateProfile, updateProfileLoading } = useUpdateProfile()

  const onUpdateProfile = () => {
    if (!profile) {
      return
    }
    handleUpdateProfile({
      name,
      profile: {
        birthedOn,
        gender: profile?.profile.gender,
      },
    })
  }

  return getProfileLoading ? (
    <FullPageLoading />
  ) : (
    <Page title="Personal Details" onBack={backToProfile}>
      <EditProfileMain>
        <TextFieldFormItem label="Name" value={name} onChange={setName} />
        <TextFieldDatePickerFormItem
          label="Date of birth"
          value={birthedOn}
          onChange={setBirthedOn}
        />
        <TextFieldFormItem
          label="Gender"
          value={profile?.profile.gender}
          readOnly
        />

        <Button
          $type="primary"
          onClick={onUpdateProfile}
          loading={updateProfileLoading}
        >
          Save
        </Button>

        <Divider style={{ borderColor: "#ccc" }} />

        <TextFieldFormItem label="Email" value={email} readOnly />
        <Button $type="secondary" onClick={goToChangeEmail}>
          Change email
        </Button>

        <Divider style={{ borderColor: "#ccc" }} />

        <TextFieldFormItem label="Password" value="***********" readOnly />
        <Button $type="secondary" onClick={goToChangePassword}>
          Change password
        </Button>

        <Divider style={{ borderColor: "#ccc" }} />

        <Button $type="danger" onClick={goToDeleteAccount}>
          Delete account
        </Button>
      </EditProfileMain>
    </Page>
  )
}

export default EditProfile
