import requester from "api/requester"

const paymentAPI = {
  getSecret(productId: string): Promise<any> {
    return requester
      .post("/payment/checkout", {
        productId,
      })
      .then((data) => {
        return data.clientSecret
      })
  },

  getPlans(): Promise<any> {
    return requester.get("/payment/plans")
  },

  getCheckoutStatus(sessionId: string): Promise<any> {
    return requester.get("/payment/checkout/session", { sessionId })
  },

  getUserPlan(): Promise<any> {
    return requester.get("/payment/plan")

  },

  getPaymentLink(productId: string): Promise<any> {
    return requester.get("/payment/customer-portal")}
}

export default paymentAPI
