import styled from "styled-components"

export const StyledNutritionItem = styled.div`
  --label-width: 70px;

  display: flex;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid var(--Schemes-Surface-Container, #ebedf0);
  }
`
export const StyledLabel = styled.label`
  position: absolute;
  text-align: left;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 16px;
  line-height: 160%;
`

export const StyledInput = styled.input<{ $isError: boolean }>`
  padding: 15.5px 16px;
  padding-left: var(--label-width);
  line-height: 26px;
  font-weight: 500;
  border-radius: 8px;

  background: var(#fff);
  color: ${(props) =>
    props.$isError
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-Primary, #0DA3BA)"};

  text-align: right;
  width: 100%;
  height: 100%;
  font-size: 16px;

  &,
  &:focus,
  &:active,
  &:focus-visible {
    border: none;
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: none;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--Schemes-Outline, rgba(0, 38, 58, 1));
    font-size: 16px;
    font-weight: 400;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--Schemes-Outline, rgba(0, 38, 58, 1));
    font-size: 16px;
    font-weight: 400;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

export const DetailsTrailing = styled.div<{ $isUnit?: boolean }>`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--color-navy);

  ${(props) =>
    props.$isUnit &&
    `
    color: var(--Schemes-Primary, #0DA3BA);
    font-weight: 500;
    `}
`
