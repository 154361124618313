import { Fragment } from "react"
import { FlexBox } from "components/Common/FlexBox"
import {
  DailyNutritionTitle,
  DailyText,
  NutritionBox,
  NutritionPercent,
  NutritionValue,
  PlanDescription,
  PlanDescriptionStrong,
  DailyNutrition,
  PlanKg,
  YourGoalOverview,
  PlanStartTime,
  StyledNutrition,
  YourGoalContent,
  YourGoalBmr,
  BmrTitle,
  BmrValue,
  BmrDescription,
} from "./styled"
import ArrowLeft from "components/Icons/ArrowLeft"
import { useMetricType, useUser } from "features/onboarding/hooks"
import { IMetricType } from "features/onboarding/types"
import { getDisplayCalories, getWeight, truncateByDecimalPlace } from "utils"
import { gramToKg } from "utils"
import dayjs from "dayjs"
import { useAppSelector } from "app/hooks"
import { Link } from "react-router-dom"
import PATHS from "router/paths"
import ChevronLeft from "components/Icons/ChevronLeft"
import CtaBox from "components/Common/CtaBox"
import Button from "components/Buttons/Button"
import { GOAL_WORDING, MAINTAIN_WEIGHT_TYPES, US_DATE_FORMAT_WITH_YEAR } from "config"

export interface INutritionProps {
  label: string
  value: string
  color: string
  percent: number
}

const Nutrition = (props: INutritionProps) => {
  const { label, value, color, percent } = props
  return (
    <StyledNutrition>
      <FlexBox $justify="space-between">
        <NutritionValue>{label}</NutritionValue>
        <NutritionValue>{value}</NutritionValue>
      </FlexBox>
      <NutritionPercent $color={color} $percent={percent} />
    </StyledNutrition>
  )
}

const getDisplayedTargetWeight = (metricType: IMetricType, weight: number) => {
  if (metricType === "IMPERIAL") {
    return weight
  }

  return gramToKg(weight)
}

const Header = () => {
  return (
    <FlexBox
      style={{ padding: "9px 6px", background: "rgb(245, 247, 247)" }}
      $justify="space-between"
      $alignItems="center"
      $gap={12}
    >
      <Link
        to={PATHS.app.profile.root}
        style={{
          textDecoration: "none",
          color: "currentcolor",
          paddingLeft: 8,
        }}
      >
        <FlexBox $gap={12} $alignItems="center">
          <ChevronLeft />
          <div style={{ fontSize: 16 }}>Profile</div>{" "}
        </FlexBox>
      </Link>
    </FlexBox>
  )
}

const YourGoal = () => {
  const goal = useAppSelector((state) => state.onboarding.goal)

  const { user } = useUser()
  const { metricType } = useMetricType()

  const displayedStartWeight = getWeight(metricType, user)
  let displayedTargetWeight = getDisplayedTargetWeight(
    metricType,
    goal?.targetWeight ?? 0,
  )

  displayedTargetWeight = truncateByDecimalPlace(displayedTargetWeight, 2)

  const nutrition = [
    {
      label: "Calories",
      value: `${getDisplayCalories(goal?.caloriesPerDay)} cal/day`,
      color: "var(--color-primary)",
      percent: 100,
    },
    {
      label: "Protein",
      value: `${getDisplayCalories(goal?.proteinGram)} g`,
      color: "#F17EE6",
      percent: (goal?.protein ?? 0) * 100,
    },
    {
      label: "Fat",
      value: `${getDisplayCalories(goal?.fatGram)} g`,
      color: "#F39D39",
      percent: (goal?.fat ?? 0) * 100,
    },
    {
      label: "Carbs",
      value: `${getDisplayCalories(goal?.carbsGram)} g`,
      color: "#8653E0",
      percent: (goal?.carb ?? 0) * 100,
    },
  ]

  const weightUnit = metricType === "IMPERIAL" ? "lb" : "kg"

  return (
    <Fragment>
      <Header />
      <YourGoalContent>
        {MAINTAIN_WEIGHT_TYPES.includes(goal?.typeName ?? "") ? (
          <YourGoalOverview>
            <PlanDescription>Try maintain the current</PlanDescription>

            <FlexBox
              $gap={12}
              $justify="center"
              $alignItems="center"
              style={{ width: "100%" }}
            >
              <PlanKg>{displayedStartWeight}</PlanKg>
            </FlexBox>
          </YourGoalOverview>
        ) : (
          <Fragment>
            <YourGoalOverview>
              <FlexBox
                $gap={12}
                $justify="center"
                $alignItems="center"
                style={{ width: "100%" }}
              >
                <PlanKg>{displayedStartWeight}</PlanKg>
                <ArrowLeft />
                <PlanKg>
                  {" "}
                  {displayedTargetWeight} {weightUnit}
                </PlanKg>
              </FlexBox>

              <PlanStartTime>
                Start time:{" "}
                {goal && dayjs(goal.startDate).format(US_DATE_FORMAT_WITH_YEAR)}
              </PlanStartTime>

              <PlanDescription>
                Follow our recommendations and you can reach your goal on{" "}
                <PlanDescriptionStrong>
                  {goal &&
                    dayjs(goal.reachedOn).format(US_DATE_FORMAT_WITH_YEAR)}
                </PlanDescriptionStrong>
              </PlanDescription>
            </YourGoalOverview>
          </Fragment>
        )}

        <YourGoalBmr>
          <BmrTitle>Your daily calorie needs are:</BmrTitle>
          <BmrValue>{getDisplayCalories(goal?.bmr ?? 0)} cal/day</BmrValue>
          <BmrDescription>
            Maintenance calories are the number of calories needed to maintain
            your current weight by matching your energy intake with your energy
            expenditure
          </BmrDescription>
        </YourGoalBmr>

        <DailyNutrition>
          <DailyNutritionTitle>Daily nutrients</DailyNutritionTitle>
          <NutritionBox>
            {nutrition.map((item, index) => (
              <Nutrition key={index} {...item} />
            ))}
          </NutritionBox>

          <DailyText>{goal ? GOAL_WORDING[goal.typeName] : ""}</DailyText>
        </DailyNutrition>
      </YourGoalContent>

      <CtaBox>
        <Link
          to={PATHS.app.updateGoal}
          style={{ display: "block", width: "100%" }}
        >
          <Button style={{ width: "100%" }} $type="primary">
            Change Goal
          </Button>
        </Link>
      </CtaBox>
    </Fragment>
  )
}

export default YourGoal
