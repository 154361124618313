import {
  FoodImageBox,
  FoodInfoBox,
  FoodLeft,
  FoodName,
  FoodNutrient,
  SelectButtonWrapper,
  StyledFoodListItem,
  UncheckedIcon,
  CheckedIcon,
} from "./styled"

import { getDisplayCalories, truncateByDecimalPlace } from "utils"
import { IFood } from "features/food/foodTypes"
import SmallFoodImage from "components/SmallFoodImage"
import { IScannedFood } from "features/meal/types"
import { useScannedImageUploader } from "features/meal/hooks/useScannedImageUploader"

const SelectButton = ({
  checked,
  toggleSelectFood,
}: {
  checked?: boolean
  toggleSelectFood: () => void
}) => {
  return (
    <SelectButtonWrapper onClick={() => toggleSelectFood()}>
      {checked ? (
        <CheckedIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </CheckedIcon>
      ) : (
        <UncheckedIcon />
      )}
    </SelectButtonWrapper>
  )
}

interface IFoodListItemProps {
  food: IScannedFood
  toggleSelectFood?: (food: IFood | IScannedFood) => void
  onAdd?: (food: IFood) => void
  style?: React.CSSProperties
  selected?: boolean
  onViewDetails?: (food: IFood) => void
}

const FoodListItemSelectAble = (props: IFoodListItemProps) => {
  const { food, toggleSelectFood, onAdd, style = {}, onViewDetails } = props
  const { name, imageUrl, calorie, unit, portion } = food

  const { displayImageUrl: notAlreadyFoodImageUrl } = useScannedImageUploader(
    food.id,
  )

  const displayImageUrl = food.isNotAlreadyFood
    ? notAlreadyFoodImageUrl
    : imageUrl

  return (
    <StyledFoodListItem style={style}>
      <FoodLeft onClick={() => onViewDetails?.(food)}>
        <FoodImageBox>
          <SmallFoodImage src={displayImageUrl} />
        </FoodImageBox>
        <FoodInfoBox>
          <FoodName>{name}</FoodName>

          <FoodNutrient>
            {getDisplayCalories(calorie)} cal,{" "}
            {truncateByDecimalPlace(portion, 2)} {unit.toLocaleLowerCase()}
          </FoodNutrient>
        </FoodInfoBox>
      </FoodLeft>

      <SelectButton
        checked={food.isSelected}
        toggleSelectFood={() => {
          toggleSelectFood?.(food)
        }}
      />
    </StyledFoodListItem>
  )
}

export default FoodListItemSelectAble
