import requester from "api/requester"
import { IDailyScanProgress, IMeal } from "./types"
import { ILogMealPayload, IScanMealPayload } from "./mealTypes"
import { IFood } from "features/food/foodTypes"
import { transformObjectKeysToSnakeCase } from "api/converter"

interface IMealAPI {
  scan: (payload: IScanMealPayload) => Promise<IFood[]>
  logMeal: (payload: ILogMealPayload) => Promise<IMeal>
  processImage: (file: File) => Promise<any>
  getMealDetails: (id: string) => Promise<IMeal>
  getSuggestedMeals: () => Promise<IMeal[]>
  getDailyScanProgress: () => Promise<IDailyScanProgress>
  updateMeal: (payload: ILogMealPayload) => Promise<IMeal>
}

const getRequestLogMealPayload = (payload: ILogMealPayload) => {
  payload.ingredients = payload.ingredients?.map((ingredient: any) => {
    return {
      ...ingredient,
      externalFoodId: ingredient.externalFoodId ?? "",
    }
  })
  return transformObjectKeysToSnakeCase(payload)
}

const getLogMealFormDataPayload = (requestPayload: any) => {
  const formData = new FormData()

  Object.keys(requestPayload).forEach((key) => {
    const data = (requestPayload as any)[key]
    if (data === undefined || data === null || data === "") return

    if (key === "ingredients" || key === "units") {
      formData.append(
        key,
        new Blob([JSON.stringify(data)], {
          type: "application/json",
        }),
      )
    } else {
      formData.append(key, data)
    }
  })

  return formData
}

const mealAPI: IMealAPI = {
  logMeal(payload) {
    const requestPayload = getRequestLogMealPayload(payload)
    const formData = getLogMealFormDataPayload(requestPayload)
    return requester.post("/meals", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  updateMeal(payload) {
    const { id } = payload ?? ""
    delete payload.id

    const requestPayload = getRequestLogMealPayload(payload)
    const formData = getLogMealFormDataPayload(requestPayload)

    return requester.put(`/meals/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  scan(payload) {
    const formData = new FormData()
    if (payload.image) {
      formData.append("image", payload.image)
    } else {
      formData.append("image_id", payload.imageId ?? "")
    }

    return requester.post("/scans", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
 
  },

  processImage(file) {
    const formData = new FormData()
    formData.append("image", file)

    return requester.post("/images/process", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  },

  getMealDetails(id) {
    return requester.get(`/meals/${id}`)
  },

  async getDailyScanProgress() {
    const user = await requester.get("/users/info")

    return {
      remainingScan: user.aiScan?.scanLeft, 
      enabled: user.aiScan?.enabled,
    }
  },

  getSuggestedMeals() {
    return requester.get("/meals/suggestions")
  },
}

export default mealAPI
