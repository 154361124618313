import React from "react"
import { useCheckoutStatus } from "../hooks"
import FullPageLoading from "components/Layouts/FullPageLoading"
import {
  CloseButtonBox,
  OrAction,
  PaymentSuccessBody,
  PaymentSuccessDescription,
  PaymentSuccessSmallTitle,
  PaymentSuccessTitle,
  PaymentSuccessWrapper,
  SuccessBanner,
  SuccessBannerWrapper,
} from "./styled"
import WhiteClosingIcon from "components/Icons/WhiteClosingIcon"
import BannerSuccessBanner from "assets/images/paywall-success-banner.png"
import Button from "components/Buttons/Button"
import { FlexBox } from "components/Common/FlexBox"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"

const CheckoutStatus = () => {
  const { getCheckoutStatusLoading, checkoutStatus } = useCheckoutStatus()

  const isCompleted = checkoutStatus?.status === "complete"

  const navigate = useNavigate()

  const backToUserPlan = ()=>{
    navigate(PATHS.app.userPlan)
  }

  if (getCheckoutStatusLoading) {
    return <FullPageLoading />
  }

  if (isCompleted) {
    return (
      <PaymentSuccessWrapper>
        <CloseButtonBox onClick={backToUserPlan}>
          <WhiteClosingIcon />
        </CloseButtonBox>

        <PaymentSuccessBody>
          <PaymentSuccessSmallTitle>
            Payment Successful
          </PaymentSuccessSmallTitle>

          <PaymentSuccessTitle>
            Your health journey just got an upgrade!
          </PaymentSuccessTitle>

          <PaymentSuccessDescription>
            Download the ZOZOFIT app for easier access to Food Journal, body
            scanning and other advanced features to maximize your success.
          </PaymentSuccessDescription>

          <SuccessBannerWrapper>
            <SuccessBanner src={BannerSuccessBanner} alt="success" />
          </SuccessBannerWrapper>

          <FlexBox
            $direction="column"
            $gap={16}
            style={{ width: "100%", marginTop: 24, marginBottom: "auto" }}
          >
            <Button $borderLess $type="primary">Download the IOS app</Button>
            <OrAction>Or</OrAction>
            <Button $borderLess $type="primary" onClick={backToUserPlan}>Continue to Food Journal</Button>
          </FlexBox>
        </PaymentSuccessBody>
      </PaymentSuccessWrapper>
    )
  }

  return <div>{JSON.stringify(checkoutStatus)}</div>
}

export default CheckoutStatus
