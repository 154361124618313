import requester from "api/requester"
import { IAuthAPI, ILoginWithApplePayload } from "./types"
import zozoFitRequester from "api/zozoFitRequester"

const authAPI: IAuthAPI = {
  loginWithEmail: (payload) => {
    return requester.post(`/accounts/token/sign-in`, payload)
  },

  loginWithApple: (payload: ILoginWithApplePayload) => {
    return requester.post(`/accounts/token/social-sign-in`, payload)
  },

  loginWithMfa(payload) {
    return requester.post(`/accounts/token`, payload)
  },

  logOut(refreshToken) {
    return requester.delete(`/accounts/token`, { refreshToken })
  },

  deleteAccount() {
    return zozoFitRequester.delete(`/v2/accounts`)
  }
}

export default authAPI
