import requester from "api/requester"
import React, { useCallback } from "react"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js"
import { stripePromise } from "libs/stripe"
import paymentAPI from "../paymentAPI"
import { CheckoutContent, CheckoutWrapper } from "./styled"
import Header from "components/Header"
import PATHS from "router/paths"

const Checkout = () => {
  const params = useParams()
  const fetchClientSecret = useCallback(() => {
    if (params.id) {
      return paymentAPI.getSecret(params.id)
    }

    return new Promise((resolve, reject) => {})
    // Create a Checkout Session
  }, [])

  const options = { fetchClientSecret }

  const navigate = useNavigate()
  const onBack = () => {
    navigate(generatePath(PATHS.app.root))
  }

  return (
    <CheckoutWrapper>
      <Header title="Checkout" onBack={onBack} />
      <CheckoutContent>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </CheckoutContent>
    </CheckoutWrapper>
  )
}

export default Checkout
