import { createSlice } from "@reduxjs/toolkit"
import mealAPI from "features/meal/mealAPI"
import { createAppAsyncThunk } from "app/hooks"
import {
  NETWORK_ERROR_MESSAGE,
  OUT_OF_DAILY_SCAN_MESSAGE,
  resetStoreAction,
  USER_UPLOADED_IMAGE_PREFIX,
  WELCOME_MESSAGE,
} from "config"
import { IScannedFood, MealState } from "./types"
import { ILogMealPayload, IScanMealPayload } from "./mealTypes"
import { convertServerToClientFood } from "features/food/foodConverter"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"
import { isNotAlreadyFood } from "utils"

export const logMeal = createAppAsyncThunk(
  "meal/logMeal",
  async (payload: ILogMealPayload, { rejectWithValue, getState }) => {
    try {
      const date = getState().home.selectedDate
      const isToDay = dayjs(date).isSame(dayjs(), "day")
      if (date && !isToDay) {
        payload.date = date.format("YYYY-MM-DD")
      }

      const response = await mealAPI.logMeal(payload)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const logScannedFoods = createAppAsyncThunk(
  "meal/logScannedFoods",
  async (payload: ILogMealPayload[], { rejectWithValue, getState }) => {
    try {
      const date = getState().home.selectedDate
      const isToDay = dayjs(date).isSame(dayjs(), "day")
      if (date && !isToDay) {
        payload = payload.map((item) => ({
          ...item,
          date: date.format("YYYY-MM-DD"),
        }))
      }
      const requests = payload.map((item) => mealAPI.logMeal(item))
      const responses = await Promise.all(requests)
      return responses
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const updateMeal = createAppAsyncThunk(
  "meal/updateMeal",
  async (payload: ILogMealPayload, { rejectWithValue }) => {
    try {
      const response = await mealAPI.updateMeal(payload)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const scanMeal = createAppAsyncThunk(
  "meal/scanMeal",
  async (payload: IScanMealPayload, { rejectWithValue }) => {
    try {
      const foods = await mealAPI.scan(payload)
      return foods.map((food) => convertServerToClientFood(food))
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getSuggestedMeals = createAppAsyncThunk(
  "meal/getSuggestedMeals",
  async (_, { rejectWithValue }) => {
    try {
      const meals = await mealAPI.getSuggestedMeals()
      return meals
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const onImageLoaded = (src: string) =>
  new Promise((resolve) => {
    if (!src) resolve(true)
    const img = new Image()
    img.src = src

    img.onload = function () {
      resolve(true)
    }
  })

export const processImage = createAppAsyncThunk(
  "meal/processImage",
  async (file: File, { rejectWithValue }) => {
    try {
      const response = await mealAPI.processImage(file)
      await onImageLoaded(response.url)

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const processScannedFoodImage = createAppAsyncThunk(
  "meal/processScannedFoodImage",
  async (
    { file, foodId }: { file: File; foodId: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await mealAPI.processImage(file)
      await onImageLoaded(response.url)

      return { ...response, foodId }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getMealDetails = createAppAsyncThunk(
  "meal/getMealDetails",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await mealAPI.getMealDetails(id)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getDailyScanProgress = createAppAsyncThunk(
  "meal/getDailyScanProgress",
  async (_, { rejectWithValue }) => {
    try {
      const response = await mealAPI.getDailyScanProgress()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: MealState = {
  scannedFoodUploadedImageDict: {},
  processScannedFoodImageLoading: false,
  processScannedFoodImageFailed: undefined,

  logScannedFoodsLoading: false,
  logScannedFoodsFailed: undefined,
  logScannedFoodsSuccess: undefined,

  updateMealLoading: false,
  updateMealFailed: undefined,
  updateMealSuccess: undefined,

  mealDetailsBackPath: undefined,

  logMealLoading: false,
  logMealSuccess: undefined,
  logMealFailed: undefined,

  suggestedMeals: [],
  getSuggestedMealsLoading: false,
  getSuggestedMealsFailed: undefined,

  dailyScanProgress: {
    remainingScan: 0,
    enabled: false,
  },

  getDailyScanProgressLoading: false,
  getDailyScanProgressFailed: undefined,

  scanMealResults: [],
  scanMealLoading: false,
  scanMealFailed: undefined,
  imageBase64Encoded: "",
  convertImageLoading: false,
  step: "init",
  messages: [
    {
      owner: "kagami",
      texts: [WELCOME_MESSAGE],
      isWelcome: true,
    },
  ],

  file: undefined,

  imageUrl: "",
  imageId: "",
  processImageLoading: false,
  processImageFailed: undefined,
  displayImageType: "base64",

  mealDetails: undefined,
  getMealDetailsLoading: false,
  getMealDetailsFailed: undefined,
}

export const searchSlice = createSlice({
  name: "textChat",
  initialState,
  reducers: {
    revertScannedFOodToOriginImageState(state, { payload }) {
      state.scannedFoodUploadedImageDict[payload.foodId] =
        payload.originImageState
    },
    uploadScannedFoodImage(state, { payload }) {
      state.scannedFoodUploadedImageDict[payload.foodId] = {
        imageFile: payload.image,
        base64: payload.base64,
      }
    },

    removeScannedFoodUploadedImage(state, { payload }) {
      state.scannedFoodUploadedImageDict[payload.foodId] = {}
    },

    setMealDetailsBackPath(state, { payload }) {
      state.mealDetailsBackPath = payload
    },

    setMealDetails(state, { payload }) {
      state.mealDetails = payload
    },

    uploadImage(state, { payload }) {
      state.step = "image-uploaded"

      state.imageBase64Encoded = payload.base64
      state.file = payload.file
      state.displayImageType = "base64"
      state.imageUrl = ""
      state.imageId = ""
    },

    setStep(state, { payload }) {
      if (payload === "init") {
        return initialState
      }

      state.step = payload
    },

    removeUploadedImage(state) {
      state.file = undefined
      state.imageBase64Encoded = ""
      state.imageId = ""
      state.imageUrl = ""
    },

    resetMealState() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(processScannedFoodImage.pending, (state) => {
        state.processScannedFoodImageLoading = true
      })
      .addCase(processScannedFoodImage.fulfilled, (state, { payload }) => {
        state.processScannedFoodImageLoading = false
        state.scannedFoodUploadedImageDict[payload.foodId] = {
          imageUrl: payload.url,
          imageId: payload.id,
        }
      })

      .addCase(processScannedFoodImage.rejected, (state, { payload }) => {
        state.processScannedFoodImageLoading = false
        state.processScannedFoodImageFailed = payload
      })

      .addCase(logScannedFoods.pending, (state) => {
        state.logScannedFoodsLoading = true
      })
      .addCase(logScannedFoods.fulfilled, (state, { payload }) => {
        state.logScannedFoodsLoading = false
        state.logScannedFoodsSuccess = payload
      })

      .addCase(logScannedFoods.rejected, (state, { payload }) => {
        state.logScannedFoodsLoading = false
        state.logScannedFoodsFailed = payload
      })

      .addCase(updateMeal.pending, (state) => {
        state.updateMealLoading = true
      })
      .addCase(updateMeal.fulfilled, (state, { payload }) => {
        state.updateMealLoading = false
        state.updateMealSuccess = payload
      })
      .addCase(updateMeal.rejected, (state, { payload }) => {
        state.updateMealLoading = false
        state.updateMealFailed = payload
      })

      .addCase(logMeal.pending, (state) => {
        state.logMealLoading = true
      })
      .addCase(logMeal.fulfilled, (state, { payload }) => {
        state.logMealLoading = false
        state.logMealSuccess = payload
      })
      .addCase(logMeal.rejected, (state, { payload }) => {
        state.logMealLoading = false
        state.logMealFailed = payload
      })

      .addCase(getSuggestedMeals.pending, (state) => {
        state.getSuggestedMealsLoading = true
      })
      .addCase(getSuggestedMeals.fulfilled, (state, { payload }) => {
        state.getSuggestedMealsLoading = false
        state.suggestedMeals = payload
      })
      .addCase(getSuggestedMeals.rejected, (state, { payload }) => {
        state.getSuggestedMealsLoading = false
        state.getSuggestedMealsFailed = payload
        state.suggestedMeals = []
      })

      .addCase(scanMeal.pending, (state) => {
        state.scanMealLoading = true
        state.step = "scan-animation"

        const image =
          state.displayImageType && state.displayImageType === "base64"
            ? state.imageBase64Encoded
            : state.imageUrl

        state.messages = [
          {
            owner: "user",
            texts: [USER_UPLOADED_IMAGE_PREFIX + image],
          },
        ]

        // state.imageBase64Encoded = ""
        // state.imageUrl = ""
        // state.file = undefined
      })
      .addCase(scanMeal.fulfilled, (state, { payload }) => {
        state.scanMealResults = payload.map((food) => {
          return {
            ...food,
            isSelected: true,
            originalId: food.id,
            id: uuidv4(),
            isNotAlreadyFood: isNotAlreadyFood(food),
          } as IScannedFood
        })

        state.scannedFoodUploadedImageDict = state.scanMealResults.reduce(
          (acc, food) => {
            return {
              ...acc,
              [food.id]: {
                imageId: state.imageId,
                imageUrl: state.imageUrl,

                imageFile: state.file,
                base64: state.imageBase64Encoded,
              },
            }
          },
          {},
        )

        state.scanMealLoading = false
        state.imageId = ""
        state.imageUrl = ""
        state.imageBase64Encoded = ""
        state.file = undefined

        if (payload.length === 0) {
          state.step = "not-found"
          return
        }

        state.step = "scan-result"
      })
      .addCase(scanMeal.rejected, (state, { payload }) => {
        state.scanMealLoading = false
        state.scanMealFailed = payload
        state.step = "init"
        state.messages = [NETWORK_ERROR_MESSAGE]

        state.imageId = ""
        state.imageUrl = ""
        state.imageBase64Encoded = ""
        state.file = undefined
      })

      .addCase(processImage.pending, (state) => {
        state.processImageLoading = true
        state.imageUrl = ""
        state.imageId = ""
        state.imageBase64Encoded = ""
        state.displayImageType = "url"
        state.step = "image-uploaded"
      })

      .addCase(processImage.fulfilled, (state, { payload }) => {
        state.imageUrl = payload.url
        state.imageId = payload.id
        state.processImageLoading = false
      })

      .addCase(processImage.rejected, (state, { payload }) => {
        state.processImageLoading = false
        state.imageUrl = ""
        state.imageId = ""
        state.processImageFailed = payload
        state.step = "init"
      })

      .addCase(getMealDetails.pending, (state) => {
        state.getMealDetailsLoading = true
      })
      .addCase(getMealDetails.fulfilled, (state, { payload }) => {
        state.getMealDetailsLoading = false
        state.mealDetails = payload
      })
      .addCase(getMealDetails.rejected, (state, { payload }) => {
        state.getMealDetailsLoading = false
        state.getMealDetailsFailed = payload
      })

      .addCase(getDailyScanProgress.pending, (state) => {
        state.getDailyScanProgressLoading = true
        state.getDailyScanProgressFailed = undefined
      })
      .addCase(getDailyScanProgress.fulfilled, (state, { payload }) => {
        state.getDailyScanProgressLoading = false
        state.dailyScanProgress = payload

        if (payload.remainingScan > 0) {
          const isPlural = payload.remainingScan > 1
          state.messages[1] = {
            owner: "kagami",
            texts: [
              `You can scan ${payload.remainingScan} meal${
                isPlural ? "s" : ""
              } today. Make sure to come back tomorrow for more! 😊`,
            ],
          }
        } else {
          state.messages[1] = OUT_OF_DAILY_SCAN_MESSAGE
        }
      })
      .addCase(getDailyScanProgress.rejected, (state, { payload }) => {
        state.getDailyScanProgressLoading = false
        state.getDailyScanProgressFailed = payload
        state.dailyScanProgress = undefined
      })

      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const {
  uploadImage,
  setStep,
  resetMealState,
  setMealDetails,
  removeUploadedImage,
  setMealDetailsBackPath,
  uploadScannedFoodImage,
  removeScannedFoodUploadedImage,
  revertScannedFOodToOriginImageState,
} = searchSlice.actions

export default searchSlice.reducer
