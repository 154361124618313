import { Form } from "antd-mobile"
import { useLoginWithEmail, useRemoveMfaSection } from "features/auth/hooks"
import { ILoginWithEmailPayload } from "features/auth/types"
import { Fragment, useEffect, useState } from "react"
import { Body, ForgotPassword, Header, OrDivider, Title } from "./styled"
import ChevronLeft from "components/Icons/ChevronLeft"
import TextFieldFormItem from "components/TextFieldFormItem"
import Button from "components/Buttons/Button"
import { Link } from "react-router-dom"
import PATHS from "router/paths"
import SignInWithAppleButton from "../../Auth/SignInWithApple"
import { useAppSelector } from "app/hooks"

const SignInWithEmailForm = () => {
  const [form] = Form.useForm<ILoginWithEmailPayload>()
  const { handleLoginWithEmail, loginWithEmailLoading } = useLoginWithEmail()
  const prevEmailPayload = useAppSelector(
    (state) => state.auth.prevEmailPayload,
  )

  const onFinish = (values: ILoginWithEmailPayload) => {
    handleLoginWithEmail(values)
  }

  const [isEmailError, setIsEmailError] = useState(false)
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [hasFinishFailed, setHasFinishFailed] = useState(false)

  const onFinishFailed = ({ errorFields }: any) => {
    const emailError = errorFields.some(
      (error: any) => error.name[0] === "email",
    )
    const passwordError = errorFields.some(
      (error: any) => error.name[0] === "password",
    )

    setIsEmailError(emailError)
    setIsPasswordError(passwordError)

    setHasFinishFailed(true)
  }

  const onFieldsChange = (changedFields: any) => {
    if (hasFinishFailed) {
      if (changedFields.length === 1) {
        const isEmailChanged = changedFields[0].name[0] === "email"
        if (isEmailChanged) {
          setIsEmailError(changedFields[0].errors.length > 0)
        }

        const isPasswordChanged = changedFields[0].name[0] === "password"
        if (isPasswordChanged) {
          setIsPasswordError(changedFields[0].errors.length > 0)
        }
      } else {
        const emailError = changedFields.some(
          (field: any) => field.name[0] === "email" && field.errors.length > 0,
        )

        const passwordError = changedFields.some(
          (field: any) =>
            field.name[0] === "password" && field.errors.length > 0,
        )

        setIsEmailError(emailError)
        setIsPasswordError(passwordError)
      }
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      email: prevEmailPayload.email,
      password: prevEmailPayload.password,
    })
  }, [prevEmailPayload])

  return (
    <Fragment>
      <div
        style={{
          height: 6,
          background: "var(--Schemes-Surface-Container-Low, #F5F7F7)",
        }}
      />
      <Header $alignItems="center" $justify="space-between">
        <Link to={PATHS.auth.root}>
          <ChevronLeft />
        </Link>
        <Title>Login</Title> <div />
      </Header>

      <Body>
        <Form
          onFieldsChange={onFieldsChange}
          initialValues={prevEmailPayload}
          layout="horizontal"
          disabled={loginWithEmailLoading}
          requiredMarkStyle={"none"}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          footer={
            <Button
              style={{ width: "100%" }}
              $type="primary"
              loading={loginWithEmailLoading}
              type="submit"
            >
              Login
            </Button>
          }
        >
          <Form.Item
            noStyle
            name={"email"}
            rules={[
              {
                required: true,
                message: "Please input valid email",
                type: "email",
              },
            ]}
          >
            <TextFieldFormItem
              isError={isEmailError}
              label="Email"
              placeholder="Enter your email"
            />
          </Form.Item>

          <Form.Item
            noStyle
            label="Password"
            name={"password"}
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}
          >
            <TextFieldFormItem
              isError={isPasswordError}
              label="Password"
              placeholder="Enter your password"
              type="password"
            />
          </Form.Item>

          <ForgotPassword to={PATHS.auth.forgotPassword}>Forgot password?</ForgotPassword>
        </Form>

        <OrDivider>or</OrDivider>

        <SignInWithAppleButton />
      </Body>
    </Fragment>
  )
}

export default SignInWithEmailForm
