import { useAppSelector } from "app/hooks"
import { FlexBox } from "components/Common/FlexBox"
import CloseIcon from "components/Icons/CloseIcon"
import { USER_UPLOADED_IMAGE_PREFIX } from "config"
import { useBackToHome, useProcessImage } from "features/meal/hooks/hooks"
import { useDailyScanProgress } from "features/meal/hooks/scanMealHooks"
import {
  forwardRef,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { getUserUploadedImageUrl } from "utils"
import {
  UploadedImage,
  UploadedPhotoWrapper,
  StyledScannedPhoto,
  ReviewPhoto,
  CtaBox,
} from "./styled"
import { ChatContentBox } from "../Styled"
import { SpinLoading } from "antd-mobile"
import MessageItem from "components/Chat/MessageItem"
import AppLoading from "components/AppLoading"
import CreateMealLoader from "./CreateMealLoader"
import ChatCtaContent from "components/Chat/ChatCtaContent"

const UploadedPhoto = ({ src }: { src: string }) => {
  const ref = useRef<HTMLImageElement>(null)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().width)
    }
  }, [ref])

  return (
    <UploadedPhotoWrapper
      ref={ref}
      style={{
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 6,
      }}
    >
      <UploadedImage src={src} />
    </UploadedPhotoWrapper>
  )
}

interface IScanedPhotoProps {
  imageUrl: string
}

const ScannedPhoto = forwardRef<HTMLDivElement, IScanedPhotoProps>(
  ({ imageUrl }, ref) => {
    return (
      <StyledScannedPhoto ref={ref}>
        <ReviewPhoto src={imageUrl} />
        <div className="scan-to-bottom"></div>
        <div className="scan-to-top"></div>
      </StyledScannedPhoto>
    )
  },
)

const BeforeScanning = () => {
  const messages = useAppSelector((state) => state.meal.messages)
  const step = useAppSelector((state) => state.meal.step)
  const imageBase64Encoded = useAppSelector(
    (state) => state.meal.imageBase64Encoded,
  )

  const ref = useRef<HTMLDivElement>(null)

  const { getDailyScanProgressLoading, isScanDisabled } =
    useDailyScanProgress(step)
  const { processImageLoading, imageUrl, displayImageType } = useProcessImage()

  const cannedPhotoRef = useRef<HTMLDivElement>(null)

  const mealImageMessage = messages.find(
    (message) =>
      message.texts.length === 1 &&
      message.texts[0].includes(USER_UPLOADED_IMAGE_PREFIX),
  )

  const userUploadedImageUrl = mealImageMessage
    ? getUserUploadedImageUrl(mealImageMessage.texts[0])
    : ""

  const [scannedPhotoHeight, setScannedPhotoHeight] = useState(350)

  useEffect(() => {
    if (userUploadedImageUrl) {
      setScannedPhotoHeight(cannedPhotoRef.current?.clientHeight ?? 350)
    }
  }, [userUploadedImageUrl])

  const scanMealLoading = useAppSelector((state) => state.meal.scanMealLoading)

  const goToHome = useBackToHome()

  const chatContentBoxStyle = getDailyScanProgressLoading
    ? {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }
    : {}
  return (
    <Fragment>
      <FlexBox style={{ height: 44, padding: "9px 4px" }} $alignItems="center">
        <CloseIcon
          onClick={() => {
            if (!scanMealLoading) {
              goToHome()
            }
          }}
          style={{
            cursor: scanMealLoading ? "not-allowed" : "pointer",
            opacity: scanMealLoading ? 0.5 : 1,
          }}
        />
      </FlexBox>{" "}
      {userUploadedImageUrl && (
        <ScannedPhoto imageUrl={userUploadedImageUrl} ref={cannedPhotoRef} />
      )}
      <ChatContentBox ref={ref} style={chatContentBoxStyle}>
        {getDailyScanProgressLoading ? (
          <SpinLoading />
        ) : (
          <Fragment>
            {messages.map((message, index) => (
              <MessageItem key={index} message={message} />
            ))}
          </Fragment>
        )}
      </ChatContentBox>

      {!scanMealLoading && displayImageType === "base64" && imageBase64Encoded && (
        <UploadedPhoto src={imageBase64Encoded} />
      )}

      {!scanMealLoading && displayImageType === "url" && imageUrl && (
        <UploadedPhoto src={imageUrl} />
      )}

      {processImageLoading && <AppLoading content="Processing image" />}
      {step === "scan-animation" && userUploadedImageUrl && (
        <CreateMealLoader scannedPhotoHeight={scannedPhotoHeight} />
      )}
      <CtaBox>
        <ChatCtaContent disableScan={isScanDisabled} />
      </CtaBox>
      <div style={{ fontWeight: 900, height: 0, overflow: "hidden" }}>
        For preload font bold
      </div>
    </Fragment>
  )
}

export default BeforeScanning
