import styled from "styled-components"

export const PaymentSuccessWrapper = styled.div`
  height: 100%;
  background: linear-gradient(
    180deg,
    #1e1737 0%,
    #1a1937 18.03%,
    #01273b 48.62%,
    #06192b 74.15%,
    #05050c 100%
  );
  position: relative;
`

export const CloseButtonBox = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
`

export const PaymentSuccessBody = styled.div`
  padding: 80px 24px 0px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const PaymentSuccessSmallTitle = styled.h5`
  color: var(--Secondary-Green, #4fd600);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
  text-align: center;
`

export const PaymentSuccessTitle = styled.h1`
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  color: var(--White, #ffffff);
  text-align: center;
`
export const PaymentSuccessDescription = styled.p`
  color: var(--White, #ffffff);
  text-align: center;
  font-size: 14px;
`

export const SuccessBannerWrapper = styled.div`
  width: 315px;
  height: 299px;
  margin: 24px 0px;
`

export const SuccessBanner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const OrAction =styled.div`
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 100% */
letter-spacing: 0.16px;
color: var(--white-white-75, rgba(255, 255, 255, 0.35));
text-align: center; 
text-transform: uppercase;

`