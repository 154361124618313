import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { PersistConfig } from "redux-persist"
import { IAuthState } from "features/auth/types"
import storage from "redux-persist/lib/storage"
import persistReducer from "redux-persist/es/persistReducer"
import { setupInstantsInterceptor } from "api/requester"
import persistStore from "redux-persist/es/persistStore"
import { IOnboardingState } from "features/onboarding/types"

import foodReducer from "features/food/foodSlice"
import mealReducer from "features/meal/mealSlice"
import authReducer from "features/auth/authSlice"
import onboardingReducer from "features/onboarding/onboardingSlice"
import homeReducer from "features/home/homeSlice"
import notificationReducer from "features/notification/notificationSlice"
import overviewReducer from "features/overview/overviewSlice"
import imageReducer from "features/image/imageSlice"
import paymentReducer from "features/payment/paymentSlice"
import profileReducer from "features/profile/profileSlice"
import { IPaymentState } from "features/payment/types"

const authPersistConfig: PersistConfig<IAuthState> = {
  key: "auth",
  storage: storage,
  whitelist: ["token", "refreshToken", "userId", "email"],
}

const onboardingPersistConfig: PersistConfig<IOnboardingState> = {
  key: "onboarding",
  storage: storage,
  whitelist: ["goal", "user"],
}

const paymentPersistConfig: PersistConfig<IPaymentState> = {
  key: "payment",
  storage: storage,
  whitelist: ["userPlan", "remainingLogMealTimeToShowPaywall"],
}

const store = configureStore({
  reducer: {
    image: imageReducer,
    food: foodReducer,
    auth: persistReducer<IAuthState, any>(authPersistConfig, authReducer),
    meal: mealReducer,
    onboarding: persistReducer<IOnboardingState, any>(
      onboardingPersistConfig,
      onboardingReducer,
    ),
    home: homeReducer,
    notification: notificationReducer,
    overview: overviewReducer,
    payment: persistReducer<IPaymentState, any>(
      paymentPersistConfig,
      paymentReducer,
    ),
    profile: profileReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

setupInstantsInterceptor(store)

const persistor = persistStore(store)

export { store, persistor }
