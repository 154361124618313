import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect, useState } from "react"
import {
  getCheckoutStatus,
  getPaymentLink,
  getPlans,
  getUserPlan,
  setRemainingLogMealTimeToShowPaywall,
} from "../paymentSlice"
import { useFailed } from "features/notification/hooks"
import { useNavigate, useSearchParams } from "react-router-dom"
import { IMealType } from "features/home/types"
import { REMAINING_LOG_MEAL_TIME_TO_SHOW_PAYWALL } from "config"
import PATHS from "router/paths"

export const usePlans = () => {
  const getPlansLoading = useAppSelector(
    (state) => state.payment.getPlansLoading,
  )
  const plans = useAppSelector((state) => state.payment.plans)
  const getPlansFailed = useAppSelector((state) => state.payment.getPlansFailed)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!plans.length) {
      dispatch(getPlans())
    }
  }, [])

  useFailed(getPlansFailed)

  return {
    getPlansLoading,
    plans,
  }
}

export const useCheckoutStatus = () => {
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get("session_id") || ""

  const getCheckoutStatusLoading = useAppSelector(
    (state) => state.payment.getCheckoutStatusLoading,
  )
  const checkoutStatus = useAppSelector((state) => state.payment.checkoutStatus)
  const getCheckoutStatusFailed = useAppSelector(
    (state) => state.payment.getCheckoutStatusFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (sessionId) {
      dispatch(getCheckoutStatus(sessionId))
    }
  }, [sessionId])

  useFailed(getCheckoutStatusFailed)

  return {
    getCheckoutStatusLoading,
    checkoutStatus,
  }
}

export const useUserPlan = () => {
  const getUserPlanLoading = useAppSelector(
    (state) => state.payment.getUserPlanLoading,
  )
  const userPlan = useAppSelector((state) => state.payment.userPlan)
  const getUserPlanFailed = useAppSelector(
    (state) => state.payment.getUserPlanFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getUserPlan())
  }, [])

  useFailed(getUserPlanFailed)

  return {
    getUserPlanLoading,
    userPlan,
  }
}

export const usePaymentLink = () => {
  const navigate = useNavigate()
  const getPaymentLinkLoading = useAppSelector(
    (state) => state.payment.getPaymentLinkLoading,
  )
  const paymentLink = useAppSelector((state) => state.payment.paymentLink)
  const getPaymentLinkFailed = useAppSelector(
    (state) => state.payment.getPaymentLinkFailed,
  )

  const dispatch = useAppDispatch()
  const fakeId = "1"

  const handleGetPaymentLink = () => {
    dispatch(getPaymentLink(fakeId))
  }

  useFailed(getPaymentLinkFailed)

  useEffect(() => {
    if (paymentLink) {
      window.location.replace(paymentLink)
    }
  }, [paymentLink])

  return {
    getPaymentLinkLoading,
    paymentLink,
    handleGetPaymentLink,
  }
}

export const usePaywall = () => {
  const { userPlan, getUserPlanLoading } = useUserPlan()

  const remainingLogMealTimeToShowPaywall = useAppSelector(
    (state) => state.payment.remainingLogMealTimeToShowPaywall,
  )

  const navigate = useNavigate()

  const isUserHasAPlan = !!userPlan?.plan?.id

  const [paywallVisible, setPaywallVisible] = useState(false)

  const onClosePaywall = () => {
    setPaywallVisible(false)
  }
  const dispatch = useAppDispatch()
  const onDecreaseRemainingLogMealTimeToShowPaywall = () => {
    dispatch(
      setRemainingLogMealTimeToShowPaywall(
        remainingLogMealTimeToShowPaywall - 1,
      ),
    )
  }

  const goToAddMeal = (mealType: IMealType) => {
    navigate(
      `${PATHS.app.meal.add.root}?mealType=${mealType.toLocaleLowerCase()}`,
    )
  }

  const onClickAddMeal = (mealType: IMealType) => {
    if (isUserHasAPlan) {
      goToAddMeal(mealType)
    } else {
      if (remainingLogMealTimeToShowPaywall <= 0) {
        setPaywallVisible(true)
        dispatch(
          setRemainingLogMealTimeToShowPaywall(
            REMAINING_LOG_MEAL_TIME_TO_SHOW_PAYWALL,
          ),
        )
      } else {
        goToAddMeal(mealType)
        onDecreaseRemainingLogMealTimeToShowPaywall()
      }
    }
  }

  return {
    onClickAddMeal,
    paywallVisible,
    onClosePaywall,
  }
}
