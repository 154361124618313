import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  DetailsTrailing,
  StyledInput,
  StyledLabel,
  StyledNutritionItem,
} from "./styled"
import { validateMaximumToDecimalPlace } from "utils"

interface IProps {
  label: string
  value: number
  onChange: (value: number) => void
  placeholder?: string
  detailTrailing?: string
  setIsFormValid?: (isValid: boolean) => void
  disabled?: boolean
}

const NutritionFormItem = (props: IProps) => {
  const { value, onChange, setIsFormValid, detailTrailing, disabled } = props
  const [localValue, setLocalValue] = useState<string>(value.toString())
  const trailingRef = useRef<HTMLDivElement>(null)
  const [trailingWidth, setTrailWidth] = useState(0)

  useEffect(() => {
    if (value !== Number(localValue)) {
      setLocalValue(value.toString())
    }
  }, [value])

  const handleChange = (value: string) => {
    if (!validateMaximumToDecimalPlace(value) || value === "0.00") {
      return
    }

    if (value === "" || value === null) {
      setLocalValue("")
      setIsFormValid?.(false)
      return
    }

    let nextValue = Number(value)

    if (nextValue === 0) {
      setLocalValue(value)
      setIsFormValid?.(false)
      return
    }

    if (!isNaN(nextValue)) {
      setLocalValue(value)
      onChange(nextValue)
      setIsFormValid?.(true)
    }
  }

  const onBlur = () => {
    const nextValue = value ?? 1
    if (localValue !== nextValue.toString()) {
      setLocalValue(nextValue.toString())
    }

    setIsFormValid?.(true)

    onChange(nextValue)
  }

  useLayoutEffect(() => {
    setTrailWidth(trailingRef.current?.getBoundingClientRect().width ?? 0)
  }, [trailingRef, detailTrailing])

  return (
    <StyledNutritionItem
      style={
        {
          "--input-padding-right": detailTrailing ? "16px" : "16px",
          "--input-trailing-padding-left": detailTrailing ? "8px" : "0px",
          "--detail-trailing-width": `${trailingWidth}px`,
        } as React.CSSProperties
      }
    >
      {props.label && <StyledLabel>{props.label}</StyledLabel>}

      <StyledInput
        style={{
          paddingRight:
            "calc(var(--input-padding-right) + var(--input-trailing-padding-left) + var(--detail-trailing-width))",
        }}
        onBlur={() => {
          onBlur()
        }}
        min={0}
        $isError={false}
        placeholder={props.placeholder}
        value={props.value ?? ""}
        onChange={(e) => {
          if (disabled) {
            return
          }
          handleChange(e.target.value)
        }}
        autoComplete="new-password"
      />
      {detailTrailing && <DetailsTrailing>{detailTrailing}</DetailsTrailing>}

      {detailTrailing && (
        <DetailsTrailing style={{ visibility: "hidden" }} ref={trailingRef}>
          {detailTrailing}
        </DetailsTrailing>
      )}
    </StyledNutritionItem>
  )
}

export default NutritionFormItem
