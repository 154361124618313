import { useLogMeal, useSetMealTypeParams } from "../hooks/hooks"
import {
  AddFoodScanBox,
  AddFoodScanBoxInner,
  RecentMealMain,
  TryFoodScanText,
  Wrapper,
  RecentFoodsWrapper,
  RecentFoodsTabBtn,
  FoodList,
} from "./styled"
import { Outlet, useMatch, useNavigate } from "react-router-dom"
import ScanMealIcon from "components/Icons/ScanMealIcon"
import PATHS from "router/paths"
import { useSetPageTitle } from "libs/ga"
import {
  useCheckShouldShowTutorial,
  useIsTutorialShowed,
} from "features/onboarding/hooks"
import { useLogBrazeEvent } from "libs/braze"
import Header from "./Header"
import SearchAutocomplete from "components/SearchAutocomplete"
import { FlexBox } from "components/Common/FlexBox"
import SearchFoodOverlay from "components/SearchFoodOverlay"

import { useSearchVisibleInAddMealPage } from "features/food/hooks"
import { useGoToFoodDetails } from "features/food/hooks/useFoodDetails"
import { useEffect, useState } from "react"
import { IAPIFood, IFood, IMyFood } from "features/food/foodTypes"
import APIFoodDetails from "components/APIFoodDetails"
import MyFoodDetails from "components/MyFoodDetails"
import { useAppSelector } from "app/hooks"
import { AI_ENTITLEMENT } from "config"

enum IDisplaySection {
  Main,
  FoodDetails,
}

const AddMeal = () => {
  const [displaySection, setDisplaySection] = useState<IDisplaySection>(
    IDisplaySection.Main,
  )
  const [foodDetails, setFoodDetails] = useState<IFood | null>(null)

  useSetMealTypeParams

  const { isSearchInAddMealPageVisible, setSearchInAddMealPageVisible } =
    useSearchVisibleInAddMealPage()
  const { logBrazeManualInput, logBrazeTryAI } = useLogBrazeEvent()

  const { isShowTutorial, checkShowTutorialLoading } =
    useCheckShouldShowTutorial()
  const { handleTutorialShowedChange } = useIsTutorialShowed()

  useEffect(() => {
    handleTutorialShowedChange(false)
  }, [])

  const { mealType } = useSetMealTypeParams()

  const isMyFoodPage = useMatch(PATHS.app.meal.add.myFoods)
  const isSuggestedPage = useMatch(PATHS.app.meal.add.root)

  const navigate = useNavigate()

  const { handleLogMealByFood } = useLogMeal()
  const userPlan = useAppSelector((state) => state.payment.userPlan)

  const isAIDisabled = !userPlan?.plan?.entitlements.find(en=>en.name === AI_ENTITLEMENT)

  console.log("isAIDisabled", isAIDisabled)

  const onTryAI = () => {
    if (checkShowTutorialLoading || isAIDisabled) {
      return
    }

    logBrazeTryAI()
    if (isShowTutorial) {
      navigate(`${PATHS.app.meal.tutorial}?mealType=${mealType}`)
    } else {
      navigate(`${PATHS.app.meal.scan}?mealType=${mealType}`)
    }
  }

  const onSelectFood = (food: IFood) => {
    setFoodDetails(food)
    setDisplaySection(IDisplaySection.FoodDetails)
  }

  const onCloseFoodDetails = () => {
    setFoodDetails(null)
    setDisplaySection(IDisplaySection.Main)
  }

  if (displaySection === IDisplaySection.FoodDetails && foodDetails) {
    return foodDetails.isExternal ? (
      <APIFoodDetails
        mode="log"
        food={foodDetails as IAPIFood}
        onBack={onCloseFoodDetails}
        // onAdd={handleLogMealByFood}
      />
    ) : (
      <MyFoodDetails
        mode="log"
        food={foodDetails as IMyFood}
        onBack={onCloseFoodDetails}
      />
    )
  }
  return (
    <Wrapper>
      <Header />

      <RecentMealMain>
        <FlexBox style={{ padding: "0 16px" }} $direction="column" $gap={16}>
          <SearchAutocomplete
            onFocus={() => {
              if (!isSearchInAddMealPageVisible) {
                setSearchInAddMealPageVisible(true)
              }
            }}
            placeholder="Search"
            value={""}
            onChange={() => {}}
            onClose={() => {}}
            onBlur={() => {}}
            onSearch={() => {}}
          />

          <AddFoodScanBox>
            <AddFoodScanBoxInner
            $disabled={isAIDisabled || checkShowTutorialLoading}
              $loading={checkShowTutorialLoading}
              onClick={onTryAI}
            >
              <ScanMealIcon />
              <TryFoodScanText> Food Scanner</TryFoodScanText>
            </AddFoodScanBoxInner>
          </AddFoodScanBox>
        </FlexBox>

        <RecentFoodsWrapper>
          <FlexBox $gap={12}>
            <RecentFoodsTabBtn
              $isActive={!!isSuggestedPage}
              to={`${PATHS.app.meal.add.root}?mealType=${mealType}`}
            >
              Suggestion
            </RecentFoodsTabBtn>
            <RecentFoodsTabBtn
              $isActive={!!isMyFoodPage}
              to={`${PATHS.app.meal.add.myFoods}?mealType=${mealType}`}
            >
              My foods
            </RecentFoodsTabBtn>
          </FlexBox>

          <FoodList>
            <Outlet />
          </FoodList>
        </RecentFoodsWrapper>

        <SearchFoodOverlay
          visible={isSearchInAddMealPageVisible}
          onClose={() => {
            setSearchInAddMealPageVisible(false)
          }}
          onAdd={(food) => {
            handleLogMealByFood(food)
          }}
          onSelect={(food) => {
            onSelectFood(food)
          }}
        />
      </RecentMealMain>
    </Wrapper>
  )
}

export default AddMeal
