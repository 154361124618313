import DatePicker from "components/DatePicker"
import { StyledPopup } from "components/StyledPopup"
import TextFieldFormItem from "components/TextFieldFormItem"
import { DATE_PARAM_FORMAT, US_DATE_FORMAT } from "config"
import dayjs from "dayjs"
import { Fragment, useState } from "react"

interface ITextFieldDatePickerFormItemProps {
  value: string
  onChange: (date: string) => void
  label:string
}

const TextFieldDatePickerFormItem = (props: ITextFieldDatePickerFormItemProps) => {
  const { value, onChange , label} = props
  const [showCalendar, setShowCalendar] = useState(false)
  return (
    <Fragment>
      <StyledPopup
        position="top"
        visible={showCalendar}
        onMaskClick={() => setShowCalendar(false)}
      >
        <DatePicker
          onChange={(date) => {
            onChange(date.format(DATE_PARAM_FORMAT))
            setShowCalendar(false)
          }}
          value={dayjs(value, DATE_PARAM_FORMAT)}
        />
      </StyledPopup>

      <TextFieldFormItem readOnly value={value}  label={label} onFocus={()=>setShowCalendar(true)}/>
    </Fragment>
  )
}

export default TextFieldDatePickerFormItem
