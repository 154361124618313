import React from "react"
import { DeleteAccountBody, WarningBox, WarningIconBox, WarningText } from "./styled"
import { PageTitle } from "components/Common/PageTitle"
import Button from "components/Buttons/Button"
import Page from "components/Common/Page"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { useDeleteAccount } from "features/auth/hooks"
import { FlexBox } from "components/Common/FlexBox"

const WarningIcon = () => {
  return (
    <WarningIconBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49"
        height="50"
        viewBox="0 0 49 50"
        fill="none"
      >
        <path
          d="M24.4966 18.9715V27.0107M24.4966 35.0499H24.5167M21.7137 8.70482L5.18327 37.2573C4.26639 38.841 3.80795 39.6329 3.8757 40.2828C3.9348 40.8497 4.23179 41.3648 4.69275 41.6999C5.22125 42.0842 6.13624 42.0842 7.96621 42.0842H41.027C42.857 42.0842 43.772 42.0842 44.3005 41.6999C44.7614 41.3648 45.0584 40.8497 45.1175 40.2828C45.1853 39.6329 44.7268 38.841 43.81 37.2573L27.2796 8.70481C26.366 7.12679 25.9092 6.33778 25.3132 6.07278C24.7933 5.84163 24.1999 5.84163 23.68 6.07278C23.0841 6.33778 22.6273 7.12679 21.7137 8.70482Z"
          stroke="#FF4921"
          strokeWidth="4.01961"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </WarningIconBox>
  )
}

const DeleteAccount = () => {
  const navigate = useNavigate()

  const { handleDeleteAccount, deleteAccountLoading } = useDeleteAccount()

  const backToEditProfile = () => {
    if (!deleteAccountLoading) {
      navigate(PATHS.app.profile.edit.root)
    }
  }

  return (
    <Page title="Delete Account" onBack={backToEditProfile}>
      <DeleteAccountBody>
        <WarningBox>
          <WarningIcon />
          <PageTitle>Delete account</PageTitle>
          <WarningText>
            Are you sure you want to delete your ZOZOFIT account? You will lose
            all your measurements and data
          </WarningText>
        </WarningBox>

        <FlexBox $direction="column" $gap={12}>
          <Button
            loading={deleteAccountLoading}
            onClick={handleDeleteAccount}
            $type="danger"
          >
            Delete account
          </Button>
          <Button $type="cancel" disabled={deleteAccountLoading} onClick={backToEditProfile}>
            Cancel
          </Button>
        </FlexBox>
      </DeleteAccountBody>
    </Page>
  )
}

export default DeleteAccount
