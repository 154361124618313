import axios from "axios"
import memoizedRefreshToken from "./refreshToken"
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore"
import {
  transformObjectKeysToCamelCase,
  transformObjectKeysToSnakeCase,
} from "./converter"
import { setAxiosToken } from "./requester"
import dayjs from "dayjs"

const headers: any = {
  "X-User-Agent": "ZOZOFIT/3.0 iOS/15.0.0",
  "X-Timezone-Offset": dayjs().format("Z").split(":")[0],
}

if (import.meta.env.VITE_ZOZO_FIT_DEV_BASIC_AUTH_TOKEN) {
  headers["Authorization"] = `Basic ${
    import.meta.env.VITE_ZOZO_FIT_DEV_BASIC_AUTH_TOKEN
  }`
}

const zozoFitInstant = axios.create({
  baseURL: import.meta.env.VITE_ZOZO_FIT_API_BASE_URL,
  headers,
})

export function setZOZOFitAxiosToken(token: string) {
  zozoFitInstant.defaults.headers.common[
    "X-Authorization-ACCESSTOKEN"
  ] = `Bearer ${token}`
}
export function removeAxiosToken() {
  zozoFitInstant.defaults.headers.common["X-Authorization-ACCESSTOKEN"] = ""
}

const responseBody = (res: any) => transformObjectKeysToCamelCase(res.data)

const zozoFitRequester = {
  get: (url: string, params?: any, config = {}) =>
    zozoFitInstant
      .get(url, {
        params,
        ...config,
      })
      .then(responseBody),

  post: (url: string, data: any, config = {}) =>
    zozoFitInstant.post(url, data, config).then(responseBody),
  put: (url: string, data: any, config = {}) =>
    zozoFitInstant.put(url, data, config).then(responseBody),
  delete: (url: string, data?: any) =>
    zozoFitInstant.delete(url, { data }).then(responseBody),
  patch: (url: string, data: any) =>
    zozoFitInstant.patch(url, data).then(responseBody),
}

export const setupInstantsInterceptor = (store: ToolkitStore) => {
  zozoFitInstant.interceptors.response.use(
    (response) => {
      if (response.data) {
        response.data = transformObjectKeysToCamelCase(response.data)
      }

      return response
    },
    async (error: any) => {
      const config = error?.config

      if (axios.isCancel(error)) {
        return Promise.reject()
      }

      const isAuthEndpoints = config?.url?.includes("/accounts/token")
      const isAuthError = [401, 403].includes(error?.response?.status)

      console.log("isAuthError", error)

      if (isAuthError && !config?.sent && !isAuthEndpoints) {
        config.sent = true

        const result = await memoizedRefreshToken(store)

        if (result?.accessToken) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result.accessToken}`,
          }
          setZOZOFitAxiosToken(result.accessToken)
          setAxiosToken(result.accessToken)
          return axios(config)
        }

        return new Promise(() => {})
      }

      return Promise.reject(error)
    },
  )

  zozoFitInstant.interceptors.request.use(
    (config) => {
      if (config.url?.includes("/accounts/token")) {
        return config
      }

      const isMultipartFormData =
        config.headers["Content-Type"] === "multipart/form-data" &&
        config.data instanceof FormData

      if (config.params) {
        config.params = transformObjectKeysToSnakeCase(config.params)
      }

      if (isMultipartFormData) {
        return config
      }

      if (config.data) {
        config.data = transformObjectKeysToSnakeCase(config.data)
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}

export default zozoFitRequester
