import React from "react"

const SelectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_8281_16009)">
        <path
          d="M10.0003 1.66669C5.40033 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.6 5.40033 18.3334 10.0003 18.3334C14.6003 18.3334 18.3337 14.6 18.3337 10C18.3337 5.40002 14.6003 1.66669 10.0003 1.66669ZM7.74199 13.575L4.75033 10.5834C4.42533 10.2584 4.42533 9.73335 4.75033 9.40835C5.07533 9.08335 5.60033 9.08335 5.92533 9.40835L8.33366 11.8084L14.067 6.07502C14.392 5.75002 14.917 5.75002 15.242 6.07502C15.567 6.40002 15.567 6.92502 15.242 7.25002L8.91699 13.575C8.60033 13.9 8.06699 13.9 7.74199 13.575Z"
          fill="#2CCCD3"
        />
      </g>
      <defs>
        <clipPath id="clip0_8281_16009">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SelectedIcon
