import ChevronLeft from "components/Icons/ChevronLeft"
import {
  FoodDetailsContent,
  FoodDetailsHeader,
  FoodDetailsInfo,
  FoodName,
  IngredientList,
  FoodDetailsBody,
} from "./styled"
import { Fragment, useState } from "react"
import Button from "components/Buttons/Button"
import { useMealDetails, useMealDetailsOnBack } from "../hooks/mealDetailsHooks"
import PopConfirm from "components/PopConfirm"
import { useBackToHome, useLogMeal, useSetMealTypeParams } from "../hooks/hooks"
import { useSetPageTitle } from "libs/ga"
import { BackButton } from "components/Common/BackButton"
import FullPageLoading from "components/Layouts/FullPageLoading"
import { useUpdateLocalMeal } from "../hooks/useUpdateLocalMeal"

import { IMealType } from "features/home/types"
import MealTypePickerFormItem from "components/MealTypePicker"
import PortionUnitPickerFormItem from "components/PortionFormItemUnitPicker"
import NutrientInformation from "components/NutrientInformation"
import FoodListItem from "components/FoodListItem"
import { FoodListDivider, FoodNameBox } from "components/FoodListItem/styled"
import CtaBox from "components/Common/CtaBox"
import FoodImage from "components/FoodImage"
import { TopNavWrapper } from "components/TopNavWrapper"
import { FOOD_DETAILS_BODY_ID } from "config"
import { isMyFood } from "utils"
import MyFoodIcon from "components/Icons/MyFood"

const MealDetails = () => {
  useSetPageTitle("Meal Details")
  const { mealType } = useSetMealTypeParams()
  const { mealDetails, getMealDetailsLoading } = useMealDetails()
  const { meal, onPortionChange, onUnitChange, onMealTypeChange } =
    useUpdateLocalMeal({ initMeal: mealDetails, mealType })

  const [isPortionValid, setIsPortionValid] = useState(true)

  const handlePortionChange = (value: number) => {
    onPortionChange(value)
    setIsPortionValid(true)
  }

  const backToHome = useBackToHome()
  const { handleLogMealByMeal, logMealLoading } = useLogMeal(backToHome)
  const onBack = useMealDetailsOnBack()
  const previewImageUrl = mealDetails?.previewImageUrl ?? mealDetails?.imageUrl
  const [imagePreviewerVisible, setImagePreviewerVisible] = useState(false)

  const [isPopConfirmDiscardOpen, setIsPopConfirmDiscardOpen] = useState(false)

  const handleBack = () => {
    const isMealChanged =
      mealDetails?.name !== meal?.name ||
      mealDetails?.type !== meal?.type ||
      mealDetails?.portion !== meal?.portion ||
      mealDetails?.unit !== meal?.unit ||
      mealDetails?.ingredients !== meal?.ingredients
    if (isMealChanged) {
      setIsPopConfirmDiscardOpen(true)
    } else {
      onBack()
    }
  }

  return (
    <Fragment>
      {getMealDetailsLoading || !meal ? (
        <FullPageLoading />
      ) : (
        <Fragment>
          <FoodDetailsContent>
            <FoodDetailsBody id={FOOD_DETAILS_BODY_ID}>
              <FoodImage previewImageUrl={meal.previewImageUrl}>
                <TopNavWrapper>
                  <FoodDetailsHeader>
                    <BackButton onClick={handleBack}>
                      <ChevronLeft />
                    </BackButton>
                  </FoodDetailsHeader>
                </TopNavWrapper>
              </FoodImage>

              <FoodDetailsInfo>
                <FoodNameBox>
                  <FoodName>{meal.name}</FoodName>
                  {meal.foodId ? <MyFoodIcon /> : null}
                </FoodNameBox>

                <MealTypePickerFormItem
                  disabled
                  value={meal.type.toLocaleLowerCase()}
                  onChange={(val) =>
                    onMealTypeChange(val.toLowerCase() as IMealType)
                  }
                />

                <PortionUnitPickerFormItem
                  unitValue={meal.unit}
                  portionValue={meal.portion}
                  onPortionChange={handlePortionChange}
                  onUnitChange={onUnitChange}
                  unitOptions={meal.units}
                  isDisabledUnit={!meal.externalFoodId}
                  setIsPortionValid={setIsPortionValid}
                />

                <NutrientInformation
                  calorie={meal.calorie}
                  fat={meal.pfc.fat}
                  protein={meal.pfc.protein}
                  carbohydrate={meal.pfc.carb}
                />
                {meal.ingredients?.length ? (
                  <IngredientList>
                    {" "}
                    {meal.ingredients.map((ingredient, index) => (
                      <div key={index}>
                        <FoodListItem
                          food={ingredient}
                          hideAddBtn
                          style={{ padding: "0 8px" }}
                        />
                        {index !== (meal.ingredients?.length ?? 1) - 1 && (
                          <FoodListDivider />
                        )}
                      </div>
                    ))}
                  </IngredientList>
                ) : undefined}
              </FoodDetailsInfo>
            </FoodDetailsBody>
            <CtaBox
              style={{
                flexDirection: "row",
                background: "#fff",
              }}
            >
              <Button
                loading={logMealLoading}
                $type="primary"
                style={{ width: "100%" }}
                disabled={!isPortionValid}
                onClick={() => handleLogMealByMeal(meal)}
              >
                Log this meal
              </Button>
            </CtaBox>
          </FoodDetailsContent>

          <PopConfirm
            isOpen={isPopConfirmDiscardOpen}
            title="Unsaved changes?"
            cancelText="Discard"
            confirmText="Save changes"
            onCancel={() => onBack()}
            onConfirm={() => {
              handleLogMealByMeal(meal)
            }}
            loading={logMealLoading}
          >
            <p style={{ textAlign: "center" }}>
              Do you want to save the changes?
            </p>
          </PopConfirm>
        </Fragment>
      )}
    </Fragment>
  )
}

export default MealDetails
