import {
  FormItemLabel,
  NutritionGroupInputsWrapper,
  StyledCreateFoodForm,
} from "./styled"
import TextFieldFormItem from "components/TextFieldFormItem"
import CtaBox from "components/Common/CtaBox"
import Button from "components/Buttons/Button"
import { useCreateLocalNutritionFactsFood } from "features/food/hooks/useCreateLocalNutritionFactsFood"
import PortionUnitPickerFormItem from "components/PortionFormItemUnitPicker"
import { Fragment, useEffect, useMemo, useState } from "react"
import Header from "components/Header"
import NutritionFormItem from "./NutritionFormItem"
import { IScannedFood } from "features/meal/types"
import ImageUploader from "./ImageUploader"
import { useAppSelector } from "app/hooks"
import { useRevertFoodScanToOriginalImage } from "features/meal/hooks/useScannedImageUploader"
import AppLoading from "components/AppLoading"

interface ICreatingNutritionFactsFoodFormProps {
  food: IScannedFood
  onUpdateFood: (updatedFood: IScannedFood) => void
  onBack: () => void
}

const CreatingNutritionFactsFoodForm = (
  props: ICreatingNutritionFactsFoodFormProps,
) => {
  const { food: initFood, onUpdateFood, onBack } = props

  const [isPortionValid, setIsPortionValid] = useState(true)

  const {
    onCaloriesChange,
    onNameChange,
    onPortionChange,
    food,
    onProteinChange,
    onFatChange,
    onCarbohydrateChange,
  } = useCreateLocalNutritionFactsFood(initFood)

  const scannedFoodUploadedImageDict = useAppSelector(
    (state) => state.meal.scannedFoodUploadedImageDict,
  )
  const originFoodImage = useMemo(
    () => scannedFoodUploadedImageDict[initFood.id],
    [initFood.id],
  )

  const handleUpdateFood = () => {
    const updatedFood: IScannedFood = {
      ...initFood,
      name: food.name,
      portion: food.portion,
      unit: food.unit,
      calorie: food.calorie,
      protein: food.protein,
      fat: food.fat,
      carbohydrate: food.carbohydrate,
      imageId: food.imageId,
      image: food.image,
    }
    onUpdateFood(updatedFood)
  }

  const onRevertFoodScanToOriginalImage = useRevertFoodScanToOriginalImage(
    initFood.id,
  )

  const processScannedFoodImageLoading = useAppSelector(
    (state) => state.meal.processScannedFoodImageLoading,
  )

  return (
    <Fragment>
  
      <Header
        disabled={processScannedFoodImageLoading} 
        title=""
        onBack={() => {
          if (!processScannedFoodImageLoading) {
            onRevertFoodScanToOriginalImage()
            onBack()
          }
        }}
      />
      <StyledCreateFoodForm>
        <TextFieldFormItem
          label="Name"
          placeholder="Name your meal"
          value={food.name}
          onChange={onNameChange}
        />

        <PortionUnitPickerFormItem
          portionValue={food.portion}
          unitValue={food.unit}
          onUnitChange={() => {}}
          isDisabledUnit={true}
          onPortionChange={onPortionChange}
          setIsPortionValid={setIsPortionValid}
        />

        <NutritionGroupInputsWrapper>
          <NutritionFormItem
            label="Calories"
            detailTrailing="cal"
            value={food.calorie}
            onChange={onCaloriesChange}
            setIsFormValid={setIsPortionValid}
          />
          <NutritionFormItem
            label="Protein"
            detailTrailing="g"
            value={food.protein}
            onChange={onProteinChange}
            setIsFormValid={setIsPortionValid}
          />
          <NutritionFormItem
            label="Fat"
            detailTrailing="g"
            value={food.fat}
            onChange={onFatChange}
            setIsFormValid={setIsPortionValid}
          />
          <NutritionFormItem
            label="Carbs"
            detailTrailing="g"
            value={food.carbohydrate}
            onChange={onCarbohydrateChange}
            setIsFormValid={setIsPortionValid}
          />
        </NutritionGroupInputsWrapper>

        <div>
          <FormItemLabel>
            Image <span>{"(optional)"}</span>
          </FormItemLabel>
          <ImageUploader foodId={initFood.id} />
        </div>
      </StyledCreateFoodForm>

      <CtaBox>
        <Button
          disabled={!isPortionValid || processScannedFoodImageLoading}
          $type="primary"
          onClick={handleUpdateFood}
        >
          Save
        </Button>
      </CtaBox>

      {processScannedFoodImageLoading && (
        <AppLoading content="Processing image" />
      )}
    </Fragment>
  )
}

export default CreatingNutritionFactsFoodForm
