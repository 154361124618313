import { createSlice } from "@reduxjs/toolkit"
import { createAppAsyncThunk } from "app/hooks"
import profileAPI from "./profileAPI"
import { resetStoreAction } from "config"
import { IChangePasswordPayload, IProfileState, IUpdateProfilePayload } from "./profileTypes"

export const getProfile = createAppAsyncThunk(
  "profile/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await profileAPI.getProfile()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const updateProfile = createAppAsyncThunk(
  "profile/updateProfile",
  async (payload: IUpdateProfilePayload, { rejectWithValue }) => {
    try {
      const response = await profileAPI.updateProfile(payload)
      return payload
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const updateEmail = createAppAsyncThunk(
  "profile/updateEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await profileAPI.changeEmail(email)
      return {}
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const confirmChangeEmail = createAppAsyncThunk(
  "profile/confirmChangeEmail",
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await profileAPI.confirmChangeEmail(code)
      return {}
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getConfirmCodeToChangePassword = createAppAsyncThunk(
  "profile/getConfirmCodeToChangePassword",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await profileAPI.getConfirmCodeToChangePassword(email)
      return {}
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const changePassword = createAppAsyncThunk(
  "profile/changePassword",
  async (payload: IChangePasswordPayload, { rejectWithValue }) => {
    try {
      const response = await profileAPI.changePassword(payload)
      return {}
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)


const initialState: IProfileState = {
  changePasswordLoading: false,
  changePasswordFailed: undefined,
  changePasswordSuccess: undefined,

  getConfirmCodeToChangePasswordLoading: false,
  getConfirmCodeToChangePasswordFailed: undefined,
  getConfirmCodeToChangePasswordSuccess: undefined,

  confirmChangeEmailLoading: false,
  confirmChangeEmailFailed: undefined,
  confirmChangeEmailSuccess: undefined,

  updateEmailLoading: false,
  updateEmailFailed: undefined,
  updateEmailSuccess: false,

  updateProfileLoading: false,
  updateProfileFailed: undefined,
  updateProfileSuccess: false,

  profile: undefined,
  getProfileLoading: false,
  getProfileFailed: undefined,
}

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetImageState() {
      return initialState
    },
  },

  extraReducers(builder) {
    builder.addCase(getConfirmCodeToChangePassword.pending, (state) => {
      state.getConfirmCodeToChangePasswordLoading = true
      state.getConfirmCodeToChangePasswordFailed = undefined
      state.getConfirmCodeToChangePasswordSuccess = undefined
    })
    builder.addCase(getConfirmCodeToChangePassword.fulfilled, (state, { payload }) => {
      state.getConfirmCodeToChangePasswordLoading = false
      state.getConfirmCodeToChangePasswordSuccess = payload
    })
    builder.addCase(getConfirmCodeToChangePassword.rejected, (state, { payload }) => {
      state.getConfirmCodeToChangePasswordLoading = false
      state.getConfirmCodeToChangePasswordFailed = payload
    })

    builder.addCase(changePassword.pending, (state) => {
      state.changePasswordLoading = true
      state.changePasswordFailed = undefined
      state.changePasswordSuccess = undefined
    })
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.changePasswordLoading = false
      state.changePasswordSuccess = payload
    })
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.changePasswordLoading = false
      state.changePasswordFailed = payload
    })

    builder.addCase(confirmChangeEmail.pending, (state) => {
      state.confirmChangeEmailLoading = true
      state.confirmChangeEmailFailed = undefined
      state.confirmChangeEmailSuccess = undefined
    })
    builder.addCase(confirmChangeEmail.fulfilled, (state, { payload }) => {
      state.confirmChangeEmailLoading = false
      state.confirmChangeEmailSuccess = payload
    })
    builder.addCase(confirmChangeEmail.rejected, (state, { payload }) => {
      state.confirmChangeEmailLoading = false
      state.confirmChangeEmailFailed = payload
    })

    builder.addCase(updateEmail.pending, (state) => {
      state.updateEmailLoading = true
      state.updateEmailFailed = undefined
      state.updateEmailSuccess = false
    })
    builder.addCase(updateEmail.fulfilled, (state, { payload }) => {
      state.updateEmailLoading = false
      state.updateEmailSuccess = payload
    })
    builder.addCase(updateEmail.rejected, (state, { payload }) => {
      state.updateEmailLoading = false
      state.updateEmailFailed = payload
    })

    builder.addCase(updateProfile.pending, (state) => {
      state.updateProfileLoading = true
      state.updateProfileFailed = undefined
      state.updateProfileSuccess = false
    })
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.updateProfileLoading = false
      state.updateProfileSuccess = payload
      state.profile = payload
    })
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.updateProfileLoading = false
      state.updateProfileFailed = payload
    })

    builder.addCase(getProfile.pending, (state) => {
      state.getProfileLoading = true
      state.getProfileFailed = undefined
    })
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.getProfileLoading = false
      state.profile = payload
    })
    builder.addCase(getProfile.rejected, (state, { payload }) => {
      state.getProfileLoading = false
      state.getProfileFailed = payload
    })

    builder.addCase(resetStoreAction, () => {
      return initialState
    })
  },
})

export const { resetImageState } = profileSlice.actions

export default profileSlice.reducer
