import { useAppDispatch, useAppSelector } from "app/hooks"
import { changePassword, getConfirmCodeToChangePassword } from "../profileSlice"
import { useFailed, useSuccess } from "features/notification/hooks"
import { IChangePasswordPayload } from "../profileTypes"

export const useChangePassword = (callback?: () => void) => {
  const changePasswordLoading = useAppSelector(
    (state) => state.profile.changePasswordLoading,
  )
  const changePasswordFailed = useAppSelector(
    (state) => state.profile.changePasswordFailed,
  )
  const changePasswordSuccess = useAppSelector(
    (state) => state.profile.changePasswordSuccess,
  )

  const dispatch = useAppDispatch()

  const handleUpdatePassword = (payload: IChangePasswordPayload) => {
    dispatch(changePassword(payload))
  }

  useFailed(changePasswordFailed)
  useSuccess(changePasswordSuccess, { message: "Password changed successfully" }, callback)

  return {
    changePasswordLoading,
    changePasswordFailed,
    changePasswordSuccess,
    handleUpdatePassword,
  }
}

export const useGetConfirmCodeToChangePassword = (callback?: () => void) => {
  const getConfirmCodeToChangePasswordLoading = useAppSelector(
    (state) => state.profile.getConfirmCodeToChangePasswordLoading,
  )
  const getConfirmCodeToChangePasswordFailed = useAppSelector(
    (state) => state.profile.getConfirmCodeToChangePasswordFailed,
  )
  const getConfirmCodeToChangePasswordSuccess = useAppSelector(
    (state) => state.profile.getConfirmCodeToChangePasswordSuccess,
  )

  const dispatch = useAppDispatch()

  const handleGetConfirmCodeToChangePassword = (email: string) => {
    dispatch(getConfirmCodeToChangePassword(email))
  }

  useFailed(getConfirmCodeToChangePasswordFailed)
  useSuccess(
    getConfirmCodeToChangePasswordSuccess,
    {
      message: "",
    },
    callback,
  )

  return {
    getConfirmCodeToChangePasswordLoading,
    getConfirmCodeToChangePasswordFailed,
    getConfirmCodeToChangePasswordSuccess,
    handleGetConfirmCodeToChangePassword,
  }
}
