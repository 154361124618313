import { useState } from "react"
import { ICreateFoodPayload } from "../foodTypes"
import { IScannedFood } from "features/meal/types"

const getFoodPayloadFromScannedFood = (
  food: IScannedFood,
): ICreateFoodPayload => {
  return {
    name: food.name,
    portion: food.portion,
    unit: food.unit,
    calorie: food.calorie,
    protein: food.protein,
    fat: food.fat,
    carbohydrate: food.carbohydrate,
    ingredients: [],
  }
}

export const useCreateLocalNutritionFactsFood = (initFood: IScannedFood) => {
  const [food, setFood] = useState(()=>getFoodPayloadFromScannedFood(initFood))

  const onNameChange = (name: string) => {
    setFood({ ...food, name })
  }

  const onPortionChange = (portion: number) => {
    setFood({ ...food, portion })
  }

  const onUnitChange = (unit: string) => {
    setFood({ ...food, unit })
  }

  const onCaloriesChange = (calories: number) => {
    setFood({ ...food, calorie: calories })
  }

  const onProteinChange = (protein: number) => {
    setFood({ ...food, protein })
  }

  const onFatChange = (fat: number) => {
    setFood({ ...food, fat })
  }

  const onCarbohydrateChange = (carbohydrate: number) => {
    setFood({ ...food, carbohydrate })
  }

  const onImageChange = ({
    imageId,
    image,
  }: {
    imageId?: string
    image?: File
  }) => {
    setFood({ ...food, imageId, image })
  }

  return {
    food,
    onNameChange,
    onPortionChange,
    onUnitChange,
    onImageChange,
    onCaloriesChange,
    onProteinChange,
    onFatChange,
    onCarbohydrateChange,
  }
}
