import PageHasCta from "components/Common/PageHasCta"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { ChangeEmailBody } from "./styled"
import { PageTitle } from "components/Common/PageTitle"
import { useAppSelector } from "app/hooks"
import TextFieldFormItem from "components/TextFieldFormItem"
import { useConfirmChangeEmail, useUpdateEmail } from "../hooks/changeEmail"
import {
  MfaBody,
  MfaDescription,
  MfaTitle,
  PassCode,
} from "features/auth/Components/LogInWithEmail/WithMfa/styled"

enum IDisplaySection {
  ChangeEmail = "ChangeEmail",
  ConfirmCode = "ConfirmCode",
}

const ChangeEmail = () => {
  const navigate = useNavigate()
  const backToEditProfile = () => {
    navigate(PATHS.app.profile.edit.root)
  }

  const [displaySection, setDisplaySection] = useState(
    IDisplaySection.ChangeEmail,
  )

  const { handleUpdateEmail, updateEmailLoading } = useUpdateEmail(() => {
    setDisplaySection(IDisplaySection.ConfirmCode)
  })

  const { handleConfirmChangeEmail, confirmChangeEmailLoading } =
    useConfirmChangeEmail(backToEditProfile)

  const currentEmail = useAppSelector((state) => state.auth.email)
  const [email, setEmail] = useState("")

  const onEmailChange = (value: string) => {
    setEmail(value)
  }

  const onSubmitChangeEmail = () => {
    handleUpdateEmail(email)
  }

  const onMfaChange = (value: string) => {
    if (value.length === 6) {
      handleConfirmChangeEmail(value)
    }
  }

  const ctaText =
    displaySection === IDisplaySection.ConfirmCode ? "Next" : "Save"
  const ctaLoading =
    displaySection === IDisplaySection.ConfirmCode
      ? confirmChangeEmailLoading
      : updateEmailLoading

  return (
    <PageHasCta
      title="Change Email"
      onBack={backToEditProfile}
      ctaAction={onSubmitChangeEmail}
      ctaText={ctaText}
      ctaLoading={ctaLoading}
      ctaDisabled={email === ""}
    >
      <ChangeEmailBody>
        {displaySection === IDisplaySection.ChangeEmail ? (
          <div>
            <h1>Enter new Email</h1>
            <PageTitle>Current Email</PageTitle>
            <PageTitle>{currentEmail}</PageTitle>

            <div style={{ height: 24 }}></div>

            <TextFieldFormItem
              label="Email"
              value={email}
              onChange={onEmailChange}
            />
          </div>
        ) : (
          <MfaBody>
            <MfaTitle>Security code</MfaTitle>
            <MfaDescription style={{ display: "flex", gap: 4 }}>
              Please enter the security code we texted to {email}
            </MfaDescription>

            <PassCode plain onChange={onMfaChange} />
          </MfaBody>
        )}
      </ChangeEmailBody>
    </PageHasCta>
  )
}

export default ChangeEmail
