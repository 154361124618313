import styled from "styled-components"
import { Link } from "react-router-dom"

export const PlanCard = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Teal-Teal, #2cccd3);
  background: var(--Dark-Dark, #05050c);
  display: flex;
  height: 120px;
  padding: 30px 16px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  text-decoration: none;
  color: var(--White-White, #ffffff);
  position: relative;
`
export const PlanList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  & > * {
    width: 50%;
  }
`

export const PaywallOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000000;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.05) 100%
    ),
    #05050c;
`

export const CloseButton = styled.div`
  display: inline-flex;
  padding: 4px;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  background: var(--dark-dark-20, rgba(5, 5, 12, 0.2));
  position: absolute;
  left: 10px;
  top: 10px;
`

export const FoodImageBox = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
`

export const FoodImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const PlanName = styled.div`
  font-size: 16px;
`

export const PlanPrice = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`

export const PlansWrapper = styled.div`
  height: 100%;
  padding: 16px;
  color: var(--white-white, #ffffff);
`
export const PlanContent = styled.div`
  height: calc(100% - var(--header-height));
`
export const SelectedPlanIconBox = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`

export const PaywallTitle = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  color: var(--white-white, #ffffff);
`

export const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 0;
`

export const FeatureName = styled.div`
  font-size: 14px;
`

export const PlanCardWrapper = styled.div<{ $isSelected: boolean }>`
  border-radius: 16px;
  background: ${(props) =>
    props.$isSelected ? "var(--Teal-Teal, #2cccd3)" : "transparent"};
  width: 106px;
  padding-top: 24px;
  position: relative;
`

export const OffText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
  color: var(--Dark-Dark, #05050c);
  position: absolute;
 left: 50%;
 transform: translateX(-50%);
  top: 4px;
`
