import PageHasCta from "components/Common/PageHasCta"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { ChangePasswordBody } from "./styled"
import { useAppSelector } from "app/hooks"
import TextFieldFormItem from "components/TextFieldFormItem"
import {
  useGetConfirmCodeToChangePassword,
  useChangePassword,
} from "../hooks/changePassword"
import {
  MfaBody,
  MfaDescription,
  MfaTitle,
  PassCode,
} from "features/auth/Components/LogInWithEmail/WithMfa/styled"
import { IChangePasswordPayload } from "../profileTypes"

enum IDisplaySection {
  GetConfirmCode = "GetConfirmCode",
  InputCode = "InputCode",
}

const ChangePassword = () => {
  const email = useAppSelector((state) => state.auth.email)
  const navigate = useNavigate()
  const backToEditProfile = () => {
    navigate(PATHS.app.profile.edit.root)
  }

  const [displaySection, setDisplaySection] = useState(
    IDisplaySection.GetConfirmCode,
  )

  const { handleUpdatePassword, changePasswordLoading } =
    useChangePassword(backToEditProfile)

  const {
    handleGetConfirmCodeToChangePassword,
    getConfirmCodeToChangePasswordLoading,
  } = useGetConfirmCodeToChangePassword(() => {
    setDisplaySection(IDisplaySection.InputCode)
  })

  const [password, setPassword] = useState("")

  const onPasswordChange = (value: string) => {
    setPassword(value)
  }

  const handleSubmitGetConfirmCode = () => {
    handleGetConfirmCodeToChangePassword(email)
  }

  const onMfaChange = (value: string) => {
    if (value.length === 6) {
      const payload: IChangePasswordPayload = {
        email: email,
        confirmationCode: value,
        password: password,
      }
      handleUpdatePassword(payload)
    }
  }

  const ctaText =
    displaySection === IDisplaySection.InputCode ? "Next" : "Save"
  const ctaLoading =
    displaySection === IDisplaySection.InputCode
      ? changePasswordLoading
      : getConfirmCodeToChangePasswordLoading

  const ctaAction =
    displaySection === IDisplaySection.InputCode
      ? () => {
          onMfaChange
        }
      : handleSubmitGetConfirmCode

  return (
    <PageHasCta
      title="Change Password"
      onBack={backToEditProfile}
      ctaAction={ctaAction}
      ctaText={ctaText}
      ctaLoading={ctaLoading}
      ctaDisabled={password === ""}
    >
      <ChangePasswordBody>
        {displaySection === IDisplaySection.GetConfirmCode ? (
          <div>
            <h1>Enter new Password</h1>

            <div style={{ height: 24 }}></div>

            <TextFieldFormItem
              label="Password"
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
          </div>
        ) : (
          <MfaBody>
            <MfaTitle>Security code</MfaTitle>
            <MfaDescription style={{ display: "flex", gap: 4 }}>
              Please enter the security code we texted to {email}
            </MfaDescription>

            <PassCode plain onChange={onMfaChange} />
          </MfaBody>
        )}
      </ChangePasswordBody>
    </PageHasCta>
  )
}

export default ChangePassword
