import zozoFitRequester from "api/zozoFitRequester"
import { IChangePasswordPayload, IUpdateProfilePayload } from "./profileTypes"
import dayjs from "dayjs"

const profileAPI = {
  getProfile() {
    return zozoFitRequester.get(`/v2/users/profile`)
  },

  updateProfile(payload: IUpdateProfilePayload) {
    return zozoFitRequester.put(`/v2/users/profile`, payload)
  },

  changeEmail(email: string) {
    return zozoFitRequester.post(`/v2/accounts/email/confirmation-code`, {
      email,
    })
    // const myHeaders = new Headers()
    // myHeaders.append("Content-Type", "application/json")
    // myHeaders.append("Accept", "application/json")
    // myHeaders.append("X-User-Agent", "ZOZOFIT/3.0 iOS/15.0.0")
    // myHeaders.append("X-Timezone-Offset", dayjs().format("Z").split(":")[0])
    // myHeaders.append(
    //   "X-Authorization-IDTOKEN",
    //   "Bearer eyJraWQiOiJaTzk4bnI0Q2FvRUV3S1R4NGRGOTh2cUZRMlFSQ2REMGRWSE9LeCtHSDhvPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0NjQzMTEzYy02MjM1LTQ2YzYtYmZhOS1hY2I2NzZlYWJiMDgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfbWNtWjdOUlNHIiwiY3VzdG9tOnVzZXJfaWQiOiIwMUo4UE1XVE1WWkc2NDkzRkRNRzlIUjY1SyIsImNvZ25pdG86dXNlcm5hbWUiOiI0NjQzMTEzYy02MjM1LTQ2YzYtYmZhOS1hY2I2NzZlYWJiMDgiLCJvcmlnaW5fanRpIjoiNzRjMDBmZjYtOWI5MS00NWZhLTg4ZTEtZDNmM2JlOWNlZGM2IiwiYXVkIjoiMzdoM2E0MTR2NGNoOHQ1M3RsZzM2dDZjcnYiLCJldmVudF9pZCI6IjI5MTc1OWMzLWFhYzItNGJlNi1iNjI3LWE4YWY2YmY1ZmY3MiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNzMzOTY2MTg1LCJleHAiOjE3MzM5Njk3ODUsImlhdCI6MTczMzk2NjE4NSwianRpIjoiMDY3NzBhNDEtNzY1OC00ZDY4LWJhYjUtMDA3NzZlOWNjNDQ4IiwiZW1haWwiOiJ0dWFucGExOTkzQGdtYWlsLmNvbSJ9.TP1B6EWryLXSXjMTP6kJdTLscpNVEu-ULQQ_D6tdeI_sQ6pkttbt5TibEARK6TdQ-L45O8-4f2zUlABwll7u23KuUerbuXZkEDflb2tpyabkVSznzTXPInRryzsOJ7S-PEvGlF5Veqjwmw9PBCV3RpQlRzowarOlmKTMdtC5vBDGK9N6wNJiB1QbYjZqCPydxvprRP_Aq59VCky8Cw_8KcErZ4SmFQzm0wVGUu0ptEJVHj01Hs7muTMOmNLrn4qqppbPqCfkueGmVpnJGmfj5K4g8R9NORFPus3GTDZQ0GGZgjIPDJZyuMR-CcwZp6WI5FJhpCBqxMpYA8nJhaVFLw",
    // )
    // myHeaders.append(
    //   "X-Authorization-ACCESSTOKEN",
    //   "Bearer eyJraWQiOiJsZThFNktPTDJYdTR4QnlCV1lGd1M4NHd3bmNPN1NZdFpjWjNGXC9UVUd4VT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0NjQzMTEzYy02MjM1LTQ2YzYtYmZhOS1hY2I2NzZlYWJiMDgiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9tY21aN05SU0ciLCJjbGllbnRfaWQiOiIzN2gzYTQxNHY0Y2g4dDUzdGxnMzZ0NmNydiIsIm9yaWdpbl9qdGkiOiI3NGMwMGZmNi05YjkxLTQ1ZmEtODhlMS1kM2YzYmU5Y2VkYzYiLCJldmVudF9pZCI6IjI5MTc1OWMzLWFhYzItNGJlNi1iNjI3LWE4YWY2YmY1ZmY3MiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MzM5NjYxODUsImV4cCI6MTczMzk2OTc4NSwiaWF0IjoxNzMzOTY2MTg1LCJqdGkiOiIzOTYzMmM0Zi0zNDhhLTRhNTUtYWEzNS02MWVkYTVhYjM2MWIiLCJ1c2VybmFtZSI6IjQ2NDMxMTNjLTYyMzUtNDZjNi1iZmE5LWFjYjY3NmVhYmIwOCJ9.P38kQVxSEl7EqJ2Hu3VFq8ff9M8o8RE_2RivJPDgtCy38SMrOi4blLrH2bpKJeQHAEcdfqH_Fhuqd7NGPpajfE3Fp8NtWDv-xkeiFLBSlSRzyISIq20dwsKNFw7dreWDy63NCyJ7urBb-bRAiifyZam4Tekz_uK4tqaTQYyF6EZV2v1duWAxD-tW3TlwIJxSRoTIzNZRF-ouv8MaYb3fUHmFfW5XP-npe1VMYAtEVqMEFrRJJyqrx79hnmqUCWrtu3UuDpYxbyhen_1t2DEqnMREHK-pAVpcG8IAvc9NyoDr6Z5yVDlUG00_kgRPg3r_oeBc3chJWBk54eZVuCzRZg",
    // )
    // myHeaders.append(
    //   "Authorization",
    //   "Basic em96bzp0eCFWTjNkVC1FVUZxeFVVd0hYUk5iWkc=",
    // )

    // const raw = JSON.stringify({
    //   email: "gtcommander@gmail.com",
    // })

    // const requestOptions: any = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // }

    // fetch(
    //   "https://api.zf-develop.com/v2/accounts/email/confirmation-code",
    //   requestOptions,
    // )
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.error(error))

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({})
      }, 1000)
    })
  },

  confirmChangeEmail(code: string) {
    return zozoFitRequester.put(`/v2/accounts/email`, { code })
  },

  getConfirmCodeToChangePassword: (email: string) => {
    return zozoFitRequester.post(`/v2/accounts/password/confirmation-code`, {
      email,
    })
  },

  changePassword(payload: IChangePasswordPayload) {
    return zozoFitRequester.put(`/v2/accounts/password`, payload)
  },
}

export default profileAPI
