import React, { useEffect } from "react"
import {
  CloseButton,
  FeatureName,
  FeaturesList,
  FoodImage,
  FoodImageBox,
  OffText,
  PaywallOverlay,
  PaywallTitle,
  PlanCardWrapper,
  PlanName,
  PlanPrice,
  SelectedPlanIconBox,
} from "./styled"
import { usePlans } from "features/payment/hooks"
import FullPageLoading from "components/Layouts/FullPageLoading"
import { PlanCard, PlanContent, PlanList, PlansWrapper } from "./styled"
import { generatePath, useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import PaywallBanner from "assets/images/paywall-banner.png"
import WhiteClosingIcon from "components/Icons/WhiteClosingIcon"
import SelectedIcon from "components/Icons/SelectedIcon"
import Button from "components/Buttons/Button"
import { FlexBox } from "components/Common/FlexBox"
import CheckBlueIcon from "components/Icons/CheckBlueIcon"

const contents = [
  "Easily track macros and diet trends with just a photo",
  "AI scanning detects food and nutrition labels in seconds",
  "Makes daily logging quick and accurate",
  "Access to ZOZOFIT App Premium Features",
  "Ad-Free Experience",
]

const getPlanName = (name: string) => {
  const match = name.match(/\(([^)]+)\)/)
  return match ? match[1] : ""
}

const Paywall = ({ onClose }: { onClose: () => void }) => {
  const { plans, getPlansLoading } = usePlans()

  const [selectedPlanId, setSelectedPlanId] = React.useState<string | null>(
    null,
  )

  const navigate = useNavigate()

  const onConfirm = () => {
    navigate(
      generatePath(PATHS.app.payment.checkout.root, {
        id: selectedPlanId,
      }),
    )
  }

  useEffect(() => {
    setSelectedPlanId(plans[1]?.id)
  }, [plans])

  return (
    <PaywallOverlay>
      <FoodImageBox>
        <FoodImage src={PaywallBanner} />
        <CloseButton onClick={onClose}>
          <WhiteClosingIcon />
        </CloseButton>
      </FoodImageBox>

      {getPlansLoading ? (
        <FullPageLoading />
      ) : (
        <PlansWrapper>
          <PaywallTitle>Level up your food logging experience.</PaywallTitle>

          <FeaturesList>
            {contents.map((content, index) => (
              <FlexBox $gap={10} key={index}>
                <CheckBlueIcon />
                <FeatureName>{content}</FeatureName>
              </FlexBox>
            ))}
          </FeaturesList>

          <PlanList>
            {plans.map((plan,index) => (
              <PlanCardWrapper key={plan.id} $isSelected={index===1}>
                {index === 1 && (
                  <OffText>33$ OFF</OffText>
                )}
                <PlanCard
                  onClick={() => setSelectedPlanId(plan.id)}
                  key={plan.id}
                >
                  <PlanName>{getPlanName(plan.name)}</PlanName>
                  <PlanPrice>${plan.price}</PlanPrice>
                  {selectedPlanId === plan.id && (
                    <SelectedPlanIconBox>
                      <SelectedIcon />
                    </SelectedPlanIconBox>
                  )}
                </PlanCard>
              </PlanCardWrapper>
            ))}
          </PlanList>

          <FlexBox style={{ marginTop: 24 }}>
            <Button
            $borderLess
              $type="primary"
              style={{ width: "100%" }}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </FlexBox>
        </PlansWrapper>
      )}
    </PaywallOverlay>
  )
}

export default Paywall
