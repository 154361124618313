import { BackButton } from "components/Common/BackButton"
import { FlexBox } from "components/Common/FlexBox"
import { PageTitle } from "components/Common/PageTitle"
import ChevronLeft from "components/Icons/ChevronLeft"

const Header = ({
  onBack,
  title,
  disabled,
  onNext,
  nextText = "Next",
}: {
  onBack?: () => void
  title: string
  disabled?: boolean
  onNext?: () => void
  nextText?: string
}) => {
  return (
    <div>
      <FlexBox
        style={{ height: 44 }}
        $justify="space-between"
        $alignItems="center"
      >
        <BackButton
          onClick={() => {
            if (!disabled && onBack) {
              onBack()
            }
          }}
          style={{
            opacity: disabled ? 0.6 : 1,
            cursor: disabled ? "not-allowed" : "pointer",
            marginLeft: 5,
          }}
        >
          <ChevronLeft />
        </BackButton>

        <PageTitle>{title}</PageTitle>

        <div style={{ flexShrink: 0, width: 44 }}></div>
      </FlexBox>
    </div>
  )
}

export default Header
