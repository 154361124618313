import PageHasCta from "components/Common/PageHasCta"
import FullPageLoading from "components/Layouts/FullPageLoading"
import { usePaymentLink, useUserPlan } from "features/payment/hooks"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { UserPlanBody } from "./styled"
import InfoItem from "components/InfoItem"
import dayjs from "dayjs"
import { US_DATE_FORMAT } from "config"
import { PageTitle } from "components/Common/PageTitle"

const UserPlan = () => {
  const navigate = useNavigate()

  const onBack = () => {
    navigate(PATHS.app.profile.root)
  }

  const { getUserPlanLoading, userPlan } = useUserPlan()
  const { getPaymentLinkLoading, handleGetPaymentLink } = usePaymentLink()

  const liveTimeTitle = userPlan?.renew ? "Renew at" : "Expired at"

  const plan = userPlan?.plan

  return (
    <PageHasCta
      title="User plan"
      onBack={onBack}
      ctaAction={handleGetPaymentLink}
      ctaLoading={getPaymentLinkLoading}
      ctaText="Upgrade"
    >
      {getUserPlanLoading ? (
        <FullPageLoading />
      ) : (
        <UserPlanBody>
          <div style={{marginLeft:-16}}>

      
          <InfoItem label="Plan Name" value={plan?.name ?? ""} hasBorder />
          <InfoItem
            label="Price"
            value={`$${plan?.price ?? ""}`}
            hasBorder
          />
          <InfoItem
            label="Period"
            value={plan?.subscriptionDuration ?? ""}
            hasBorder
          />
          <InfoItem
            label={liveTimeTitle}
            value={`${userPlan?.expiredAt ? dayjs(userPlan?.expiredAt).format(US_DATE_FORMAT) : ""}`}
            hasBorder
          />

          <PageTitle style={{marginLeft:16, marginTop:24}}>Entitlements</PageTitle>
          {plan?.entitlements.map((entitlement, index) => (
            <InfoItem
              key={index}
              label={entitlement.name}
              value={''}
              hasBorder
            />
          ))}
              </div>
        </UserPlanBody>
      )}
    </PageHasCta>
  )
}

export default UserPlan
