import { s } from "vitest/dist/types-e3c9754d.js"

const PATHS = {
  app: {
    root: "/",
    setGoal: "/set-goal",
    personalInfo: "/personal-info",
    trend: "/trend",
    settings: "/settings",

    overview: "/overview",
    metric: "/metric",
    yourGoal: "/your-goal",
    updateGoal: "/update-goal",
    userPlan: "/user-plan",

    profile: {
      root: "/profile",
      edit: {
        root: "/profile/edit",
        changeEmail: "/profile/edit/change-email",
        getConfirmCodeToChangePassword: "/profile/edit/change-password",
      },
      deleteAccount: "/profile/delete-account",
    },

    payment: {
      checkout: {
        root: "/payment/:id/checkout",
        status: "/payment/checkout-status",
      },
    },

    food: {
      create: "/food/create",
      details: "/food/:id/details",
      edit: "/food/:id/edit",
    },

    meal: {
      tutorial: "/tutorial",
      details: "/meal/:id/details",
      edit: "/meal/:id/edit",
      scan: "/meal/scan",
      add: {
        root: "/meal/add",
        myFoods: "/meal/add/my-foods",
      },
    },
  },
  auth: {
    root: "/auth",
    loginWithEmail: "/auth/login-with-email",
    termOfUse: "/term-of-use",
    loginInNativeApp: "/auth/login-in-native-app",
    forgotPassword: "/auth/forgot-password",
  
  },
}

export default PATHS
