import styled from "styled-components"

export const DeleteAccountBody = styled.div`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const WarningBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 100px;
`

export const WarningIconBox = styled.div`
  display: flex;
  width: 82px;
  height: 82px;
  padding: 16.883px 16.882px 16.882px 16.883px;
  justify-content: center;
  align-items: center;

  border-radius: 40.606px;
  background: var(--Error-variant, #fddcdc);
`
export const WarningText = styled.p`
  font-size: 16px;
  text-align: center;
`
