import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  DetailsTrailing,
  StyledInput,
  StyledLabel,
  StyledInputNumberFormItem,
} from "./styled"
import { truncateByDecimalPlace } from "utils"

interface IProps {
  label: string
  value?: number
  onChange?: (value: number) => void
  placeholder?: string
  detailTrailing?: string
  isError?: boolean
  disabled?: boolean
  unit?: string
  min?: number
  max?: number
}

const InputNumberFormItem = (props: IProps) => {
  const {
    detailTrailing,
    isError = false,
    disabled = false,
    unit,
    value = "",
    onChange,
    min,
    max,
  } = props

  const [localValue, setLocalValue] = useState<number | string>(value)

  const isTouched = useRef(false)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const handleChange = (val: string) => {
    let nextValue = Number(val)

    const decimalPlace = val.split(".")[1]?.length ?? 0
    if (decimalPlace > 2) {
      return
    }

    if (
      isNaN(nextValue) ||
      nextValue === Infinity ||
      val[val.length - 1] === "."
    ) {
      setLocalValue(val)
      return
    }

    // TODO
    const formattedValue = truncateByDecimalPlace(nextValue, 2)

    if (value !== formattedValue) {
      onChange?.(formattedValue)
    }
    setLocalValue(formattedValue)
  }

  const onBlur = () => {
    isTouched.current = true

    let nextValue = value as number

    if (min && nextValue < min) {
      nextValue = min
    }

    if (max && nextValue > max) {
      nextValue = max
    }

    if (nextValue !== localValue) {
      setLocalValue(nextValue)
    }

    if (nextValue !== value) {
      onChange?.(nextValue)
    }
  }

  const trailingRef = useRef<HTMLDivElement>(null)
  const [trailingWidth, setTrailWidth] = useState(0)
  
  useLayoutEffect(() => {
    setTrailWidth(trailingRef.current?.getBoundingClientRect().width ?? 0)
  }, [trailingRef, detailTrailing])

  return (
    <StyledInputNumberFormItem
      style={
        {
          "--input-padding-right": detailTrailing ? "16px" : "16px",
          "--input-trailing-padding-left": detailTrailing ? "8px" : "0px",
          "--detail-trailing-width": `${trailingWidth}px`,
        } as React.CSSProperties
      }
    >
      {props.label && <StyledLabel>{props.label}</StyledLabel>}

      <StyledInput
        onBlur={onBlur}
        onFocus={(event) => {
          if (!isTouched.current) {
            event.target.select()
          }
        }}
        $isError={isError}
        placeholder={props.placeholder}
        value={localValue}
        onChange={(e) => {
          if (!disabled) {
            handleChange(e.target.value)
          }
        }}
        style={{
          paddingRight:
            "calc(var(--input-padding-right) + var(--input-trailing-padding-left) + var(--detail-trailing-width))",
        }}
      />
      {detailTrailing && <DetailsTrailing>{detailTrailing}</DetailsTrailing>}

      {detailTrailing && (
        <DetailsTrailing style={{ visibility: "hidden" }} ref={trailingRef}>
          {detailTrailing}
        </DetailsTrailing>
      )}

      {unit && <DetailsTrailing $isUnit>{unit}</DetailsTrailing>}
    </StyledInputNumberFormItem>
  )
}

export default InputNumberFormItem
