export interface IFoodUnit {
  label: string
  weight: number
}

export interface IBaseFood {
  id: string
  name: string
  unit: string
  portion: number
  calorie: number
  protein: number
  fat: number
  carbohydrate: number
  isExternal: boolean
}

export interface IIngredientFood extends IBaseFood {
  externalId: string
  units: IFoodUnit[]
  previewImageUrl?: string
  imageUrl?: string
}

export interface IAPIFood extends IBaseFood {
  imageUrl: string
  previewImageUrl: string
  units: IFoodUnit[]
}

export interface IMyFood extends IBaseFood {
  imageUrl: string
  imageId: string
  ingredients: IIngredientFood[]
}

export interface IFood extends IBaseFood {
  imageUrl?: string
  previewImageUrl?: string
  imageId?: string
  ingredients?: IIngredientFood[]
  units?: IFoodUnit[]
  originalId?: string
  type?: string
}

export interface IMealIngredient extends IBaseFood {
  imageUrl?: string
  previewImageUrl?: string
  imageId?: string
  units?: IFoodUnit[]
  externalFoodId: string
}

export interface ISearchFoodsPayload {
  keyword: string
  session?: string
  externalOnly?: boolean
}

export interface IGetSuggestedSearchTexts {
  keyword: string
  getSuggestedSearchTextsLoading: boolean
  getSuggestedSearchTextsFailed: any
  suggestedSearchTexts: string[]
  isNoSuggestedSearchTexts: boolean
}

export interface ISearchFoods {
  searchFoodsResults: IFood[]
  searchFoodsLoading: boolean
  searchFoodsFailed: any
  searchSession: string
  noSearchedFoodFounded: boolean
}

export interface IGetExternalSuggestedSearchTexts {
  externalKeyword: string
  getExternalSuggestedSearchTextsLoading: boolean
  getExternalSuggestedSearchTextsFailed: any
  externalSuggestedSearchTexts: string[]
  isNoExternalSuggestedSearchTexts: boolean
}

export interface ISearchExternalFoods {
  searchExternalFoodsResults: IFood[]
  searchExternalFoodsLoading: boolean
  searchExternalFoodsFailed: any
  externalSearchSession: string
  noSearchedExternalFoodFounded: boolean
}

export interface IGetMyFoods {
  myFoods: IMyFood[]
  getMyFoodsLoading: boolean
  getMyFoodsFailed: any
}

export interface ICreateFoodState {
  createFoodLoading: boolean
  createFoodSuccess?: IMyFood
  createFoodFailed: any
}

export interface IUpdateFoodState {
  updateFoodLoading: boolean
  updateFoodSuccess?: IMyFood
  updateFoodFailed: any
}

export interface ICreateFoodPayload {
  name: string
  image?: File
  imageId?: string
  imageUrl?: string
  previewImageUrl?: string
  unit: string
  portion: number
  calorie: number
  protein: number
  fat: number
  carbohydrate: number
  ingredients: IIngredientFood[]
}

export interface IUpdateFoodPayload extends ICreateFoodPayload {
  id: string
}

export interface IFoodDetailsState {
  foodDetails?: IFood
  getFoodDetailsLoading: boolean
  getFoodDetailsFailed: any
  detailsBackPath?: string
}

export interface IDeleteFoodState {
  deleteFoodLoading: boolean
  deleteFoodSuccess?: string
  deleteFoodFailed: any
}

export enum ISearchFoodOverlayDisplaySection {
  AUTOCOMPLETE = "AUTOCOMPLETE",
  SEARCH_RESULTS = "SEARCH_RESULTS",
}

export interface IFoodState
  extends IGetSuggestedSearchTexts,
    ISearchFoods,
    IGetMyFoods,
    ICreateFoodState,
    IFoodDetailsState,
    IGetExternalSuggestedSearchTexts,
    ISearchExternalFoods,
    IUpdateFoodState,
    IDeleteFoodState {
  isSearchInAddMealPageVisible: boolean
  searchFoodOverlayDisplaySection: ISearchFoodOverlayDisplaySection
}
