import React from 'react'

const CheckBlueIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_8281_15973)">
    <path d="M7.49993 13.5L4.58327 10.5833C4.25827 10.2583 3.7416 10.2583 3.4166 10.5833C3.0916 10.9083 3.0916 11.425 3.4166 11.75L6.90827 15.2417C7.23327 15.5667 7.75827 15.5667 8.08327 15.2417L16.9166 6.41666C17.2416 6.09166 17.2416 5.575 16.9166 5.25C16.5916 4.925 16.0749 4.925 15.7499 5.25L7.49993 13.5Z" fill="#67CE67"/>
    </g>
    <defs>
    <clipPath id="clip0_8281_15973">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default CheckBlueIcon