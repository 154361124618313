import styled from "styled-components"

export const StyledCreateFoodForm = styled.div`
  --cta-box-height: 64px;
  height: calc(100% - var(--header-height) - var(--cta-box-height));
  padding: 12px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`

export const FormItemLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 12px;

  span {
    font-weight: 400;
  }
`

export const NutritionGroupInputsWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--White, #fff);
`
