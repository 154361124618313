import { useAppDispatch, useAppSelector } from "app/hooks"
import { IMeal } from "../types"
import { ILogMealPayload } from "../mealTypes"
import { useFailed, useSuccess } from "features/notification/hooks"
import { updateMeal } from "../mealSlice"

export const useUpdateMeal = (callback?: () => void) => {
  const updateMealLoading = useAppSelector(
    (state) => state.meal.updateMealLoading,
  )
  const updateMealSuccess = useAppSelector(
    (state) => state.meal.updateMealSuccess,
  )
  const updateMealFailed = useAppSelector(
    (state) => state.meal.updateMealFailed,
  )

  const dispatch = useAppDispatch()

  const handleUpdateMeal = (meal: IMeal) => {
    const payload: ILogMealPayload = {
      id: meal.id,
      name: meal.name,
      unit: meal.unit,
      portion: meal.portion,
      calorie: meal.calorie,
      protein: meal.pfc.protein,
      carbohydrate: meal.pfc.carb,
      fat: meal.pfc.fat,
      ingredients: meal.ingredients,
      units: meal.units,
      type: meal.type,
    }

    if (meal.externalFoodId) {
      payload.imageUrl = meal.imageUrl
      payload.externalFoodId = meal.externalFoodId
    } else {
      payload.imageUrl = meal.imageId
      payload.foodId = meal.foodId
    }

    const isNotFoodYet = !meal.externalFoodId && !meal.foodId
    if (isNotFoodYet) {
      if (meal.imageId) {
        payload.imageId = meal.imageId
      }
      if (meal.imageUrl) {
        payload.imageUrl = meal.imageUrl
      }
    }


    dispatch(updateMeal(payload))
  }

  useFailed(updateMealFailed)
  useSuccess(updateMealSuccess, { message: "" }, callback)

  return {
    updateMealLoading,
    handleUpdateMeal,
  }
}
