import Header from "components/Header"
import React from "react"
import styled from "styled-components"
import CtaBox from "./CtaBox"
import Button from "components/Buttons/Button"

const StyledPage = styled.div`
  height: 100%;
  --header-height: 44px;
  --cta-height: 80px;
`

const StyledPageBody = styled.div`
  height: calc(100% - var(--header-height) - var(--cta-height));
`

interface IPageProps {
  title?: string
  onBack?: () => void
  children: React.ReactNode
  ctaText: string
  ctaAction: () => void
  ctaLoading?: boolean
  ctaDisabled?: boolean
  hideCta?: boolean
}

const PageHasCta = (props: IPageProps) => {
  const {
    title = "",
    onBack,
    children,
    ctaText,
    ctaAction,
    ctaLoading,
    ctaDisabled,
    hideCta = false,
  } = props
  return (
    <StyledPage>
      <Header title={title} onBack={onBack} />
      <StyledPageBody>{children}</StyledPageBody>
      {!hideCta && (
        <CtaBox>
          <Button
            loading={ctaLoading}
            disabled={ctaDisabled}
            onClick={ctaAction}
            style={{ width: "100%" }}
            $type="primary"
          >
            {ctaText}
          </Button>
        </CtaBox>
      )}
    </StyledPage>
  )
}

export default PageHasCta
