import Header from "components/Header"
import React, { ReactNode } from "react"
import styled from "styled-components"

const StyledPage = styled.div`
  height: 100%;
  --header-height: 44px;
  --cta-height: 80px;
`

const StyledPageBody = styled.div`
  height: calc(100% - var(--header-height));
  overflow-y: auto;
`

interface IPageProps {
  title?: string
  onBack?: () => void
  children: any
}

const Page = (props: IPageProps) => {
  const { title = "", onBack, children } = props
  return (
    <StyledPage>
      <Header title={title} onBack={onBack} />
      <StyledPageBody>{children}</StyledPageBody>
    </StyledPage>
  )
}

export default Page
