import styled from "styled-components"

export const CheckoutWrapper = styled.div`
  height: 100%;
`
export const CheckoutContent = styled.div`
  height: calc(100% - var(--header-height));
  padding: 16px;
  overflow-y: auto;
`
