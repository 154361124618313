import { useAppDispatch, useAppSelector } from "app/hooks"
import { confirmChangeEmail, updateEmail } from "../profileSlice"
import { useFailed, useSuccess } from "features/notification/hooks"

export const useUpdateEmail = (callback?: () => void) => {
  const updateEmailLoading = useAppSelector(
    (state) => state.profile.updateEmailLoading,
  )
  const updateEmailFailed = useAppSelector(
    (state) => state.profile.updateEmailFailed,
  )
  const updateEmailSuccess = useAppSelector(
    (state) => state.profile.updateEmailSuccess,
  )

  const dispatch = useAppDispatch()

  const handleUpdateEmail = (email: string) => {
    dispatch(updateEmail(email))
  }

  useFailed(updateEmailFailed)
  useSuccess(updateEmailSuccess, { message: "" }, callback)

  return {
    updateEmailLoading,
    updateEmailFailed,
    updateEmailSuccess,
    handleUpdateEmail,
  }
}

export const useConfirmChangeEmail = (callback?: () => void) => {
  const confirmChangeEmailLoading = useAppSelector(
    (state) => state.profile.confirmChangeEmailLoading,
  )
  const confirmChangeEmailFailed = useAppSelector(
    (state) => state.profile.confirmChangeEmailFailed,
  )
  const confirmChangeEmailSuccess = useAppSelector(
    (state) => state.profile.confirmChangeEmailSuccess,
  )

  const dispatch = useAppDispatch()

  const handleConfirmChangeEmail = (code: string) => {
    dispatch(confirmChangeEmail(code))
  }

  useFailed(confirmChangeEmailFailed)
  useSuccess(
    confirmChangeEmailSuccess,
    {
      message: "Email updated successfully",
    },
    callback,
  )

  return {
    confirmChangeEmailLoading,
    confirmChangeEmailFailed,
    confirmChangeEmailSuccess,
    handleConfirmChangeEmail,
  }
}
