"use client"

import { createPortal } from "react-dom"
import { SnackbarRoot, SnackbarWrapper } from "./Styled"
import React, { useLayoutEffect, useRef, useState } from "react"
import { ISnackbarInfo, useShowSnackbar } from "features/notification/hooks"
import { NoticeBar } from "antd-mobile"

const INIT_TRANSLATE_Y = -200
const SNACKBAR_OFFSET = 16

export default function Snackbar({
  snackbarInfo,
  index,
}: {
  snackbarInfo: ISnackbarInfo
  index: number
}) {
  const { type, description, title, status, id } = snackbarInfo
  const [style, setStyle] = useState({
    opacity: 1,
    transform: `translateY(${INIT_TRANSLATE_Y}px)`,
  })

  const { onRemoveSnackbar } = useShowSnackbar()

  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    const rect = ref.current.getBoundingClientRect()
    const snackbarHeight = SNACKBAR_OFFSET + rect.height
    const transform = `translateY(${index * snackbarHeight}px)`

    if (status === "active") {
      setStyle({
        opacity: 1,
        transform,
      })
    } else {
      setStyle({
        opacity: 0,
        transform,
      })
    }
  }, [snackbarInfo, index])

  return (
    <>
      {createPortal(
        <SnackbarRoot
          ref={ref}
          style={
            {
              ...style,
              "--animation-duration-long":
                status === "active" ? "0.275s" : "1s",
            } as React.CSSProperties
          }
        >
          <SnackbarWrapper>
            <NoticeBar
              color={(snackbarInfo as any).type}
              content={title}
              closeable
              onClose={() => {
                onRemoveSnackbar(id)
              }}
            />
          </SnackbarWrapper>
        </SnackbarRoot>,
        document.querySelector(".app") as Element,
      )}
    </>
  )
}
