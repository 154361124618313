import Page from "components/Common/Page"
import { ForgotPasswordBody } from "./styled"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { PageTitle } from "components/Common/PageTitle"
import { useState } from "react"
import TextFieldFormItem from "components/TextFieldFormItem"
import Button from "components/Buttons/Button"
import {
  useChangePassword,
  useGetConfirmCodeToChangePassword,
} from "features/profile/hooks/changePassword"
import { PasscodeInput } from "antd-mobile"
import { IChangePasswordPayload } from "features/profile/profileTypes"
import {
  MfaBody,
  MfaDescription,
  MfaTitle,
  PassCode,
} from "../LogInWithEmail/WithMfa/styled"

enum IDisplaySection {
  GetConfirmCode = "GetConfirmCode",
  InputCode = "InputCode",
  InputPassword = "InputPassword",
}

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [displaySection, setDisplaySection] = useState(
    IDisplaySection.GetConfirmCode,
  )
  const onBackToLogin = () => {
    navigate(PATHS.auth.loginWithEmail)
  }

  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")

  const {
    handleGetConfirmCodeToChangePassword,
    getConfirmCodeToChangePasswordLoading,
  } = useGetConfirmCodeToChangePassword(() => {
    setDisplaySection(IDisplaySection.InputCode)
  })

  const { handleUpdatePassword, changePasswordLoading } =
    useChangePassword(onBackToLogin)

  const onMfaChange = (value: string) => {
    if (value.length <= 6) {
      setCode(value)
    }
  }

  const onGoToInputPassword = () => {
    if (code.length === 6) {
      setDisplaySection(IDisplaySection.InputPassword)
    }
  }

  const onSubmitChangePassword = () => {
    const payload: IChangePasswordPayload = {
      email: email,
      confirmationCode: code,
      password: password,
    }
    handleUpdatePassword(payload)
  }

  return (
    <Page title="Forgot password" onBack={onBackToLogin}>
      <ForgotPasswordBody>
        {displaySection === IDisplaySection.GetConfirmCode ? (
          <div>
            <h1>Enter new Email</h1>
            <PageTitle>
              Please enter the email address you'd like your password reset
              information sent to
            </PageTitle>

            <div style={{ height: 24 }}></div>

            <TextFieldFormItem
              label="Email"
              value={email}
              onChange={setEmail}
            />

            <Button
              onClick={() => {
                handleGetConfirmCodeToChangePassword(email)
              }}
              loading={getConfirmCodeToChangePasswordLoading}
              style={{ width: "100%", marginTop: 24 }}
              $type="primary"
            >
              Continue
            </Button>
          </div>
        ) : displaySection === IDisplaySection.InputCode ? (
          <div>
            <MfaBody style={{padding:0}}>
              <MfaTitle>Email sent</MfaTitle>
              <MfaDescription style={{ display: "flex", gap: 4 }}>
                If an account exists for {email}, a reset password link has been
                sent. Check your inbox and click the email link to complete the
                change password process
              </MfaDescription>

              <PassCode plain onChange={onMfaChange} />
            </MfaBody>

            <Button
              onClick={onGoToInputPassword}
              loading={getConfirmCodeToChangePasswordLoading}
              style={{ width: "100%", marginTop: 24 }}
              $type="primary"
            >
              Done
            </Button>
          </div>
        ) : (
          <div>
            <h1>New Password</h1>
            <PageTitle>
              Secure passwords are at least 10 characters with a mixture of
              letters, numbers and symbols
            </PageTitle>

            <div style={{ height: 24 }}></div>

            <TextFieldFormItem
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
            />

            <Button
              onClick={onSubmitChangePassword}
              loading={changePasswordLoading}
              style={{ width: "100%", marginTop: 24 }}
              $type="primary"
            >
              Change password
            </Button>
          </div>
        )}
      </ForgotPasswordBody>
    </Page>
  )
}

export default ForgotPassword
