import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect, useState } from "react"
import { getProfile, updateProfile } from "../profileSlice"
import { IProfile, IUpdateProfilePayload } from "../profileTypes"
import { useFailed, useSuccess } from "features/notification/hooks"

export const useProfile = () => {
  const profile = useAppSelector((state) => state.profile.profile)
  const getProfileLoading = useAppSelector(
    (state) => state.profile.getProfileLoading,
  )
  const getProfileFailed = useAppSelector(
    (state) => state.profile.getProfileFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  return { profile, getProfileLoading, getProfileFailed }
}

export const useProfileForm = (initProfile?: IProfile) => {
  const [name, setName] = useState(initProfile?.name || "")
  const [birthedOn, setBirthedOn] = useState(
    initProfile?.profile.birthedOn || "",
  )

  useEffect(() => {
    if (initProfile) {
      setName(initProfile.name)
      setBirthedOn(initProfile.profile.birthedOn)
    }
  }, [initProfile])

  return { name, setName, birthedOn, setBirthedOn }
}

export const useUpdateProfile = () => {
  const updateProfileLoading = useAppSelector(
    (state) => state.profile.updateProfileLoading,
  )
  const updateProfileFailed = useAppSelector(
    (state) => state.profile.updateProfileFailed,
  )
  const updateProfileSuccess = useAppSelector(
    (state) => state.profile.updateProfileSuccess,
  )

  const dispatch = useAppDispatch()

  const handleUpdateProfile = (payload: IUpdateProfilePayload) => {
    dispatch(updateProfile(payload))
  }

  useSuccess(updateProfileSuccess, { message: "Profile updated successfully" })

  useFailed(updateProfileFailed)

  return {
    updateProfileLoading,
    updateProfileFailed,
    updateProfileSuccess,
    handleUpdateProfile,
  }
}
