import {
  MealSectionCalories,
  MealSectionContent,
  MealSectionFooter,
  MealSectionHeader,
  MealSectionTitle,
  StyledMealSection,
} from "./styled"
import PlusIcon from "components/Icons/PlusIcon"
import { IMeal } from "features/meal/types"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { getDisplayCalories } from "utils"
import MealItem from "./MealItem"
import { IMealType } from "../types"

interface IMealSectionProps {
  meals: IMeal[]
  mealType: IMealType
  swipedId: string | null
  setSwipedId: (id: string | null) => void
  onClickAddMeal: () => void
}

const MealSection = (props: IMealSectionProps) => {
  const { meals, mealType, swipedId, setSwipedId, onClickAddMeal } = props

  const calories = meals.reduce((acc, meal) => acc + (meal as any).calorie, 0)

  return (
    <StyledMealSection>
      <MealSectionHeader>
        <MealSectionTitle>{mealType.toLocaleLowerCase()}</MealSectionTitle>
        <MealSectionCalories>
          {getDisplayCalories(calories)}
          <span style={{ fontWeight: 400 }}> cal</span>
        </MealSectionCalories>
      </MealSectionHeader>

      <MealSectionContent>
        {meals.map((meal) => (
          <MealItem
            meal={meal}
            key={meal.id}
            swipedId={swipedId}
            setSwipedId={setSwipedId}
          />
        ))}
      </MealSectionContent>
      <MealSectionFooter onClick={onClickAddMeal}>
        <PlusIcon />
      </MealSectionFooter>
    </StyledMealSection>
  )
}

export default MealSection
