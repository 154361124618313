import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect } from "react"
import PATHS from "router/paths"
import FullPageLoading from "./FullPageLoading"
import { useGoal } from "features/onboarding/hooks"
import { TabBar } from "antd-mobile"
import DairyIcon from "components/Icons/DairyIcon"
import ProgressIcon from "components/Icons/ProgressIcon"
import ProfileIcon from "components/Icons/ProfileIcon"
import styled from "styled-components"
import { useHomePathWithSelectedDate } from "features/meal/hooks/hooks"
import ProgressActiveIcon from "components/Icons/ProgressActiveIcon"
import ProfileActiveIcon from "components/Icons/ProfileActiveIcon"
import DairyActiveIcon from "components/Icons/DairyActiveIcon"
import { useLogBrazeEvent } from "libs/braze"
import dayjs from "dayjs"

const StyledTabBar = styled(TabBar)`
  background-color: #fff;

  .adm-tab-bar-item {
    padding: 10px 0;
  }

  .adm-tab-bar-item-icon {
    height: 28px;
    width: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adm-tab-bar-item-title {
    margin-top: 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    color: var(--Schemes-On-Surface-Variant, #69757d);
  }

  .adm-tab-bar-item-active .adm-tab-bar-item-title {
    color: var(--Schemes-On-Primary-Container, #044257);
  }
`

const ProtectedLayout = () => {
  const token = useAppSelector((state) => state.auth.token)
  const navigate = useNavigate()
  const location = useLocation()

  const homePath = useHomePathWithSelectedDate()

  const isProfileActive =
    location.pathname === PATHS.app.profile.root ||
    location.pathname === PATHS.app.metric

  const tabs = [
    {
      key: "/",
      title: "Diary",
      icon: (active: boolean) => {
        return active ? <DairyActiveIcon /> : <DairyIcon />
      },
    },
    {
      key: `${PATHS.app.overview}`,
      title: "Overview",
      icon: (active: boolean) =>
        active ? <ProgressActiveIcon /> : <ProgressIcon isActive={active} />,
    },
    {
      key: PATHS.app.profile.root,
      title: "Profile",
      icon: (active: boolean) =>
        isProfileActive ? (
          <ProfileActiveIcon />
        ) : (
          <ProfileIcon isActive={isProfileActive} />
        ),
    },
  ]

  const { goal } = useGoal()

  useEffect(() => {
    if (!token) {
      navigate(PATHS.auth.root)
    }
  }, [token])

  const { logBrazeDailyTrendView } = useLogBrazeEvent()

  if (!token) {
    return <Navigate to={"/auth"} />
  }

  if (goal === undefined) {
    return <FullPageLoading />
  }

  if (
    goal === null &&
    ![PATHS.app.setGoal, PATHS.app.personalInfo].includes(location.pathname)
  ) {
    return <Navigate to={PATHS.app.personalInfo} />
  }

  if (goal && [PATHS.app.personalInfo].includes(location.pathname)) {
    return <Navigate to={PATHS.app.root} />
  }

  const noFooterPaths = [
    PATHS.app.meal.scan,
    PATHS.app.personalInfo,
    PATHS.app.setGoal,
    PATHS.app.yourGoal,
    PATHS.app.meal.add.root,
    PATHS.app.food.create,
    PATHS.app.meal.tutorial,
    PATHS.app.updateGoal,
    
    PATHS.app.profile.edit.getConfirmCodeToChangePassword,
    PATHS.app.profile.edit.changeEmail,
    PATHS.app.profile.edit.root,

    PATHS.app.payment.checkout.root,
  ]

  const isMealDetails =
    location.pathname.includes("/meal") &&
    location.pathname.includes("/details")

  const isFoodDetails =
    location.pathname.includes("/food") &&
    location.pathname.includes("/details")

  const isEditFood =
    location.pathname.includes("/food") && location.pathname.includes("/edit")

  const isEditMealPage =
    location.pathname.includes("/meal") && location.pathname.includes("/edit")

  const idAddRecent = location.pathname.includes("/add-recent")

  return (
    <div className="app-content">
      <Outlet />
      {!noFooterPaths.includes(location.pathname) &&
        !isMealDetails &&
        !isEditMealPage &&
        !idAddRecent &&
        !isFoodDetails &&
        !isEditFood && (
          <StyledTabBar
            onChange={(path) => {
              if (path === PATHS.app.root) {
                navigate(homePath)
              } else {
                if (path === PATHS.app.overview) {
                  logBrazeDailyTrendView()
                }
                navigate(path)
              }
            }}
            activeKey={location.pathname}
          >
            {tabs.map((item) => (
              <StyledTabBar.Item
                key={item.key}
                icon={item.icon}
                title={item.title}
              />
            ))}
          </StyledTabBar>
        )}
    </div>
  )
}

export default ProtectedLayout
