import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  processScannedFoodImage,
  removeScannedFoodUploadedImage,
  uploadScannedFoodImage,
  revertScannedFOodToOriginImageState,
} from "../mealSlice"
import { getBase64 } from "utils"
import { useCallback } from "react"

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const safariVersion = navigator.userAgent.match(/Version\/(\d+)/)
let isSafari17 = isSafari && safariVersion && parseInt(safariVersion[1]) >= 17

export const useUploadFoodScannedImage = (foodId: string) => {
  const scannedFoodUploadedImageDict = useAppSelector(
    (state) => state.meal.scannedFoodUploadedImageDict,
  )

  const scannedFoodImageBase64Encoded =
    scannedFoodUploadedImageDict[foodId]?.base64

  const dispatch = useAppDispatch()

  const onUploadScannedFoodImage = (base64: string, image: File) => {
    dispatch(uploadScannedFoodImage({ base64, image, foodId }))
  }

  const onRemoveScannedFoodUploadedImage = () => {
    dispatch(removeScannedFoodUploadedImage({ foodId }))
  }

  return {
    scannedFoodImageBase64Encoded,
    onUploadScannedFoodImage,
    onRemoveScannedFoodUploadedImage,
  }
}

export const useScannedFoodProcessImage = (foodId: string) => {
  const dispatch = useAppDispatch()
  const scannedFoodUploadedImageDict = useAppSelector(
    (state) => state.meal.scannedFoodUploadedImageDict,
  )

  const { imageId, imageUrl } = scannedFoodUploadedImageDict[foodId] || {}

  const processScannedFoodImageLoading = useAppSelector(
    (state) => state.meal.processScannedFoodImageLoading,
  )

  const handleProcessImage = (file: File) => {
    dispatch(processScannedFoodImage({ file, foodId }))
  }

  return {
    imageUrl,
    imageId,
    processScannedFoodImageLoading,
    handleProcessImage,
  }
}

export const useScannedImageUploader = (foodId: string) => {
  const {
    onUploadScannedFoodImage,
    scannedFoodImageBase64Encoded,
    onRemoveScannedFoodUploadedImage,
  } = useUploadFoodScannedImage(foodId)

  const { processScannedFoodImageLoading, handleProcessImage, imageUrl } =
    useScannedFoodProcessImage(foodId)

  const onScannedFoodImageChange = (file?: File) => {
    if (file) {
      const isHeicFile = file.type === "image/heic"
      const isHeifFile = file.type === "image/heif"
      const shouldConvert = isHeicFile || isHeifFile

      if (shouldConvert && !isSafari17) {
        handleProcessImage(file)
      } else {
        getBase64(file, (base64) => {
          onUploadScannedFoodImage(base64, file)
        })
      }
    }
  }

  const displayImageUrl = imageUrl || scannedFoodImageBase64Encoded

  return {
    onScannedFoodImageChange,
    onRemoveScannedFoodUploadedImage,
    processScannedFoodImageLoading,
    displayImageUrl,
  }
}

export const useRevertFoodScanToOriginalImage = (foodId: string) => {
  const dispatch = useAppDispatch()
  const scannedFoodUploadedImageDict = useAppSelector(
    (state) => state.meal.scannedFoodUploadedImageDict,
  )

  const onRevertScannedFoodToOriginalImage = useCallback(() => {
    const originImageState = scannedFoodUploadedImageDict[foodId] || {}
    dispatch(revertScannedFOodToOriginImageState({ foodId, originImageState }))
  }, [foodId])

  return onRevertScannedFoodToOriginalImage
}
